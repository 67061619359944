.news-list,
.content .news-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -6px;
	li {
		width: 25%;
		padding: 0 6px 20px;

		a {
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

	}
	.link-block-wrap {
		&:hover {
			.news-list-img {
				&:before {
					opacity: 1;
				}
			}
		}
	}
	&-img {
		position: relative;
		overflow: hidden;
		margin: 0 0 19px;
		&:after {
			position:absolute;
			bottom: -25px;
			left:-10px;
			right: -10px;
			height: 30px;
			background: #fff;
			-webkit-transform: rotate(2deg);
			-ms-transform: rotate(2deg);
			transform: rotate(2deg);
			z-index: 2;
			content: '';
		}
		&:before {
			position:absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.5);
			opacity: 0;
			-webkit-transition:opacity 0.2s linear;
			transition:opacity 0.2s linear;
			z-index: 1;
			content: '';
		}
		img {
			-webkit-transform: translate(0, 0);
			-ms-transform: translate(0, 0);
			transform: translate(0, 0);
		}
	}
	&-category {
		color: #572381;
		font-size: 1.4rem;
		line-height: 1.2;
		text-transform: uppercase;
		font-weight: 700;
		display: block;
		margin: 0 0 6px;
		font-style: italic;
	}
	&-title {
		color: #000;
		font-size: 2rem;
		line-height: 1.2;
		font-weight: 400;
		a {
			color: #000;
			&:hover,
			&:focus {
				color: #572381;
				text-decoration: none !important;
			}
		}
	}
	&_single { 
		margin: 0 -11px;
		li {
			width: 33.3%;
			padding: 0 11px 20px; 
			a {
				&:hover,
				&:focus {
					color: #000;
				}
			}
		}
		.news-list-img {
			img {
				width: 100%;
				height: auto;
			}
		}
		.news-list-title {
			margin: .5em 0 0.68em 0;
		}
	}
}

.content .news-list {
	li {
		&:before {
			display: none;
		}
	}
}