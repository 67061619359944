.location {
	&-maps {
		&-wrap {
			margin-top: 0;
		}
	}
	&-list {
		li {
			width: 100%;
			margin-right: 0;
		}
	}
	&-info {
		&-img {
			float: none;
			margin: 0 0 20px;
		}
	}
}