// @name cookies banner
.cookies-top-bloc {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  left: 50%;
  padding: 15px;
  background: darken($color-3--3, 20%);
  width: 900px;
  transform: translate(-50%, 0);
  .teaser-1 {
    margin-top: 0;
    margin-bottom: 2em;
    color: color-contrast(darken($color-3--3, 20%));
  }
  .button-2 {
    padding: 16px 10px 14px 25px;
    &:before {
      left: 0.8em;
    }
  }
}
.cookies-top-bloc__more {
  &:before {
    content: "\e003";
  }
}
.cookies-top-bloc__button-wrapper {
  text-align: right;
}

// @name cookie page
.cookie-manager {

}