// @name TYPO3 default content style
.ce-align-left {
  text-align: left;
}
.ce-align-center {
  text-align: center;
}
.ce-align-right {
  text-align: right;
}
.ce-table td, .ce-table th {
  vertical-align: top;
}
.ce-textpic, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
  overflow: hidden;
}
.ce-left .ce-gallery, .ce-column {
  float: left;
}
.ce-center .ce-outer {
  position: relative;
  float: right;
  right: 50%;
}
.ce-center .ce-inner {
  position: relative;
  float: right;
  right: -50%;
}
.ce-right .ce-gallery {
  float: right;
}
.ce-gallery figure {
  display: table;
  margin: 0;
}
.ce-gallery figcaption {
  font-size: 1.2em;
  font-style: italic;
  color: #727272;
  padding-top: 7px;
  padding-left: 14px;
  position: relative;
  display: table-caption;
  caption-side: bottom;
  @include default-icons-absolute-before("\e00f", $size: 16px, $color: #774e99, $left: -3px, $top: 5px);
  &:before {
    font-style: normal;
  }
}
.ce-gallery img {
  display: block;
}
.ce-gallery iframe {
  border-width: 0;
}
.ce-border img, .ce-border iframe {
  border: 2px solid black;
  padding: 0px;
}
.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-bottom: 20px;
}
.ce-intext.ce-right .ce-gallery {
  margin-left: 30px;
}
.ce-intext.ce-left .ce-gallery {
  margin-right: 30px;
}
.ce-below .ce-gallery {
  margin-top: 10px;
}
.ce-column {
  margin-right: 10px;
}
.ce-column:last-child {
  margin-right: 0;
}
.ce-row {
  margin-bottom: 10px;
}
.ce-row:last-child {
  margin-bottom: 0;
}
.ce-above .ce-bodytext {
  clear: both;
}
.ce-intext.ce-left ol, .ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto;
}
.ce-uploads {
  margin: 0;
  padding: 0;
}
.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}
.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top;
}
.ce-uploads span {
  display: block;
}

// @name TYPO3 text&media content blocks margin
.content {
  .ce-textpic {
    //margin: em(2, 1) 0 em(3, 1) 0;
    .ce-gallery {
      a {
        display: block;
        position: relative;
        @include default-icons-absolute-before("\e034", $size: 20px, $color: #fff, $right: 8px, $bottom: 6px);
        &:after {
          content: none;
        }
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}