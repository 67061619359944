.social-networks {
	overflow: hidden;
	li {
		float: left;
		margin: 0 5px 0 0;
	}
	a {
		width: 40px;
		height: 40px;
		position: relative;
		border: 1px solid #fff;
		display: block;
		span {
			position:absolute;
			top:53%;
			left:50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
		&:focus {
			outline-offset: -2px;
		}
	}
}