// @name List infos
.list-infos__wrapper {
  display: block;
  .list-infos__listitems + .list-infos__listitems {
    border-left: 0;
    border-top: 1px solid $color-3--3;
    padding-left: 0;
    margin-left: 0;
    padding-top: 5%;
    margin-top: 5%;
  }
}