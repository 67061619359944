.kiosk {
	&-wrap {
		padding: 0;
	}
	&-item {
		&-img {
			float: none;
			margin: 0;
		}
		&-content {
			padding-bottom: 0;
		}
	}
}