.list-type-3__picture {
  float: none;
  img {
    margin-right: 0;
    margin-bottom: 1em;
    max-width: 100%;
    height: auto;
  }
}

