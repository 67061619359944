// @name Document list
// @description List of document in publications list

.list-document-wrap {
  margin: 50px 0;
  &:after{
    content:"";
    display:block;
    clear:both;
  }
}

.list-document__listitems {
  font-size: 0;
  line-height: 0;
  margin: 0 -10px;
  .button-1, .button-2 {
    padding-left: 2.2rem;
    padding-right: 1rem;
    margin: 0 0 4px;
  }
}
.list-document__item {
  width: 33.33%;
  display:inline-block;
  vertical-align:top;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 10px 40px;
  &-one {
    width: 100%;
    max-width: 290px;
    float: left;
  }
  &--postulate {
    float: right;
    width: 50%;
    .button-2 {
      display: block;
      width: 100%;
      font-size: 2.3rem;
      text-align: center;
      padding: 20px 10px;
      margin-top:20px;
      &:before {
        position: static;
        display:inline-block;
        vertical-align:-1px;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
        font-size: 18px;
        line-height: 1;
        font-weight: 400;
        margin: 0 10px 0 0;
      }
    }
  }
}
.list-document__title-file {
  font-size: 1.4rem;
  font-weight: $bold;
  padding-left: 37px;
  margin-bottom: 16px;
  word-break: break-all;
  .list-document__file-size {
    display: block;
    font-weight: $light;
    line-height: 1;
  }
  @include default-icons-absolute-before('\e005', 35px, $color-2--2, 2px, inherit, inherit, 0, 'icons-project');
  &:before {
    font-weight: 400;
    top: 0;
    left: -4px;
  }
  &.list-document__title-file--pdf {
    &:before {
      content: "\e003";
    }
  }
  &.list-document__title-file--doc {
    &:before {
      content: "\e001";
    }
  }
  &.list-document__title-file--jpg {
    &:before {
      content: "\e002";
    }
  }
  &.list-document__title-file--xls {
    &:before {
      content: "\e006";
    }
  }
  &.list-document__title-file--ppt {
    &:before {
      content: "\e004";
    }
  }
  &.list-document__title-file--zip {
    &:before {
      content: "\e007";
    }
  }
}

.list-document__download {
  &:before {
    content: "\e063";
    font-size: 11px;
    left: 10px !important;
  }
}

.list-document__read {
  padding-right: 20px !important;
  &:before {
    content: "\e06e";
    font-size: 11px;
    left: 10px !important;
  }
  &:after {
    font-size: 9px;
    right: 8px;
    position:absolute;
    top:50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

// @name list document type 1
// @description Liste in bloc
.list-document-1__listitems {
  $list-document-1__listitems__font-size: 1.2;
  margin: 0 0 em(0.5, $list-document-1__listitems__font-size) 0;
  & + .list-document-1__listitems {
    padding: em(0.5, $list-document-1__listitems__font-size) 0 0;
  }
}
.list-document-1__item {
  $list-document-1__item__font-size: 1.2;
  font-size: #{$list-document-1__item__font-size}em;
  text-transform: uppercase;
  font-weight: $bold;
  margin: em(0.5, $list-document-1__item__font-size) 0;
  color: $color-black;
  position: relative;
  z-index: 99;
  @include default-icons-before('\e063', 0 5px 0 0, em(1.2, $list-document-1__item__font-size), $color-2--2, em(-.2, 1.2));
  a {
    color: $color-black;
    &:hover, &:focus {
      color: $color-1--2;
      text-decoration: underline;
    }
  }
  &.list-document-1__item--read {
    &:before {
      content: "\e06e";
    }
  }
}
.list-document-1__file-size {
  font-weight: $light;
}