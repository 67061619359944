// # Global
$domain: "cc-vallee-herault.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i";
$import-google-font-2: $import-google-font-1;
$import-google-font-3: "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700";

$typo-1: 'Roboto Condensed', sans-serif;
$typo-2: $typo-1;
$typo-3: $typo-1;
$typo-4: 'Open Sans Condensed', sans-serif;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-pictos-url: "../Images/pictos/";
$image-form-url: "../Images/form/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$minismartphone: 460;
$smartphone: 767;
$tablet: 959;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #7e33bb;
$color-1--2: #572381;
$color-1--3: #401a5f;
$color-1--4: #f17279;
$color-1--5: #ddd3e6;

// ## color-2
$color-2--1: #b6df14;
$color-2--2: #97b911;
$color-2--3: #7c980e;

// ## Color-3
$color-3--1: #f9f9f9;

$color-3--3: #777777;
$color-3--4: #201d1d;
$color-3--5: #727272;
$color-3--6: #c5c5c5;
$color-3--7: #fafafa;
$color-3--2: #c8c8c8;
$color-3--8: $color-3--6;
$color-3--9: #c6c6c6;
$color-3--10: #f6f4f9;

// ## Color-4
$color-4--1: #ffdc00;

// ## Color-5
$color-5--1: #5090c8;

// ## Color-6
$color-6--1: #6072ac;
