// fancyBox v2.1.4 fancyapps.com | fancyapps.com/fancybox/#license
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-galerie button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	border-radius: 0;
	text-decoration: none;
	&:before {
		background: none;
		content: "";
		speak: none;
	}
}

.fancybox-wrap p {
	font-size: 1.3em;
}

.fancybox-wrap a:focus {
	outline: 1px dotted #fff;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8020;
	//background: #fff;
	color: #000;
}
.fancybox-galerie.fancybox-wrap {
	background: #000;
}

.fancybox-skin {
	position: relative;
	text-shadow: none;
	background: #fff;
	z-index: 9000;
}
.fancybox-galerie .fancybox-skin {
	background: #000;
}

.fancybox-opened {
	z-index: 10011;
}

.fancybox-outer, .fancybox-inner {
	position: relative;

}

.fancybox-outer span {
	display: inline-block;
}

.fancybox-inner {
	overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	margin: 0;
	white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

#fancybox-loading {
	background: url("../Images/fancybox/loading_bg.png") no-repeat 0 0;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -22px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	width: 44px;
	height: 44px;
	background: url('../Images/fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
	position: absolute;
	bottom: -15px;
	right: 15px;
	padding-right: 15px;
	color: #fff;
	cursor: pointer;
	z-index: 8060;
	font-size: 1.3em;
	font-weight: $bold;
	text-decoration: none;
	background: url("../Images/fancybox/close.png") no-repeat 100% 50%;
	i {
		font-style: normal;
	}
	img {
		display: none;
	}
	&:hover, &:focus {
		color: #fff;
		font-weight: $bold;
		font-size: 1.3em;
		text-decoration: none;
		.hover {
			text-decoration: underline;
		}
	}
}

.fancybox-nav {
	position: absolute;
	bottom: -35px;
	width: 40%;
	height: 20px;
	cursor: pointer;
	text-decoration: none;
	z-index: 8070;
	color: #fff;
	font-size: 1.3em;
	&:hover, &:focus {
		.hover {
			text-decoration: underline;
		}
	}
	img {
		display: none;
	}
}

.fancybox-prev {
	left: 10%;
	text-align: right;
}
.fancybox-prev span {
	padding: 0 10px 0 20px;
	background: url("../Images/fancybox/precedent.png") no-repeat 0 50%;
}

.fancybox-next {
	right: 10%;
	text-align: left;

}
.fancybox-next span {
	padding: 0 20px 0 10px;
	background: url("../Images/fancybox/suivant.png") no-repeat 100% 50%;
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

// Overlay helper
.fancybox-lock {
	overflow: visible !important;
	body {
		overflow: hidden !important;
	}
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 8010;
	background: url('../Images/fancybox/fancybox_overlay.png');
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

// Title helper
.fancybox-title {
	visibility: hidden;
	position: relative;
	text-shadow: none;
	z-index: 8050;
	padding: 0 15px 15px 15px;
}
.fancybox-title .captionFancybox {
	padding-right: 100px;
}
.fancybox-galerie .fancybox-title .numberOfFancybox {
	margin: 0;
}
.fancybox-galerie .fancybox-title .captionFancybox {
	border-top: 1px solid #353535;
	margin-top: 10px;
	padding-top: 5px;
	padding-right: 0;
}
.fancybox-opened .fancybox-title {
	visibility: visible;
	background: transparent;
	width: 100%;
}

.fancybox-title-float-wrap {
	position: absolute;
	bottom: 0;
	left: -15px;
	margin-bottom: -55px;
	z-index: 8050;
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	padding: 2px 0;
	color: #FFF;
	font-weight: $bold;
	line-height: 24px;
}

.fancybox-title-outside-wrap {
	position: relative;
	color: #fff;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}

.fancybox-more-infos {
	display: none;
}

#fancybox-buttons {
	position: fixed;
	width: 150px;
	height: 30px;
	bottom: 0;
	left: 0;
	text-align: left;
	z-index: 8050;
	.btnPlay {
		color: #fff;
		text-decoration: none;
		font-size: 1.3em;

		@include img-before(relative, transparent, $image-fancybox-url, 'play.png', 30px, 30px, 1, -4px, inherit, inherit, 0);
		padding-left: 40px;
		img {
			display: none;
		}
		.hover {
			margin-top: 2px;
			display: block;
		}
		&:hover, &:focus {
			span {
				text-decoration: underline;
			}
		}
		&.btnPlayOn {
			&:before {
				background-image: url('#{$image-fancybox-url}pause.png');
			}
		}
	}
}
