.ddm.ddm__content + .ddm.ddm__content {
  margin-top: -3.9em;
}
.ddm.ddm__content {
  margin: 4em 0 4em 0;
  .ddm__title {
    margin-bottom: 0,3em;
  }
  .ddm__sub-level {
    position: static;
    top: inherit;
    right: inherit;
    z-index: inherit;
    display: block;
    height: auto;
    overflow: hidden;
    max-height: 0;
    transition: max-height ease .2s;
  }
  &.ddm--active {
    .ddm__title {
      margin-bottom: 0;
    }
    .ddm__sub-level {
      border: 1px solid #eaeaea;
      padding: 1.5em 19px 1.6em;
      max-height: 100em;
      transition: max-height ease-in-out 1s;
      margin-bottom: 50px;
      overflow: scroll;
      p {
        line-height: 1.4;
        margin-bottom: 20px;
      }
      .title {
        border-bottom: 1px solid #eaeaea;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        padding-bottom: 9px;
        padding-left: 10px;
        margin-bottom: 10px;
        & + p {
          font-size: 1.3em;
        }
      }
      h4 {
        font-size: 15px;
        padding-left: 17px;
        margin-top: 0;
        margin-bottom: 6px;
        & + p {
          font-size: 1.3em;
          margin-bottom: 6px;
        }
      }
      ol.list-ol {
        margin-left: 30px;
        margin-top: 0;
        font-size: 1.3em;
        li {
          margin-bottom: 0;
        }
        ol {
          padding-left: 0;
          margin-bottom: 0;
          font-size: 12px;
        }
      }
    }
  }
}

.ddm__content {
  .ddm__button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: em(2, 1.4);
    font-family: $typo-3;
    font-weight: $bold;
    background: #97b911;
    padding: em(1, 2) em(4, 2) em(1, 2) em(1, 2);
    position: relative;
    transition: all ease .3s;
    white-space: normal;
    color: #fff;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50px;
      background: #81a341;
      z-index: 1;
    }
    @include default-icons-absolute-before('\e000', em(1.11, 2), #fff, 50%, em(1.6, 1), inherit, inherit);
    &:before {
      z-index: 2;
      transform: translateY(-50%);
    }
  }
  &.ddm--active {
    .ddm__button {
      background: $color-1--2;
      color: $color-white;
      &:before {
        content: "\e001";
        color: $color-white;
      }
      &:after {
        background: #482269;
      }
    }
  }
}