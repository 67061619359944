.kiosk {
	padding: 11px 0 30px;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	&-wrap {
		padding: 0 0 0 10.7%;
		margin-top: -9px;
		&:after{
			content:"";
			display:block;
			clear:both;
		}
	}
	&-item {
		width: 50%;
		padding: 0 20px;
		float: left;
		position: relative;
		&-wrap {
			float: left;
			width: calc(100% - #{220px});
		}
		&-img {
			float: left;
			margin: 0 20px 0 0;
		}
		&-content {
			padding: 32px 0 20px;
			overflow: hidden;
		}
		&-title {
			font-size: 3rem;
			line-height: 1.2;
			color: #000;
			text-transform: uppercase;
			a {
				color: #000;
			}
		}
		&-subtitle {
			font-weight: 300;
			font-size: 3rem;
			line-height: 1.2;
			display: block;
			font-style: italic;
			margin: 0 0 20px;
			padding: 0 0 18px;
			position: relative;
			&:after {
				position:absolute;
				bottom: 0;
				left:0;
				background: #000;
				width: 75px;
				height: 1px;
				content: '';
			}
		}
	}
	&-options-list {
		li {
			margin: 0 0 9px;
		}
		a {
			z-index: 1;
		}
	}
	.newsletter {
		float: right;
	}
	@media (min-width: 961px) and (max-width: 1170px) {
		&-wrap {
			padding: 0;
			margin: 0 0 0 -20px;
		}
		&-item {
			padding: 0 0 0 20px;
		}
	}
}