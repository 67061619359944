// @name Search box
// @description Search module
.search-box {

  form {
    margin: 0;
  }

  .ddm {
    position: static;
    &__sub-level {
      top: 0;
      right: 0;
      left: auto;
      padding: 0 70px 0 97px;
      width: calc(100% - #{297px});
      height: calc(100% - #{7px});
      display: none;
      align-items: center;
      overflow: hidden;
      z-index: 99;
      &:after {
        position:absolute;
        top:-20px;
        left:13px;
        width: 100%;
        height: 200px;
        -webkit-transform: rotate(-3.9deg) skew(3deg);
        -ms-transform: rotate(-3.9deg) skew(3deg);
        transform: rotate(-3.9deg) skew(3deg);
        background: #6072ac;
        z-index: -1;
        content: '';
      }
    }
    &.ddm--active {
      .ddm__sub-level {
        display: flex;
      }
    }
  }

  .ddm__title {
    position:absolute;
    top:0;
    right: 0;
    button {
      width: 8.3rem;
      height: 9.6rem;
      margin-top: 4px;
      position: relative;
      overflow: hidden;
      font-size: 1.1rem;
      line-height: 1.2;
      color: #fff;
      text-transform: uppercase;
      font-weight: 300;
      z-index: 1;
      padding: 63px 5px 10px 13px;
      background: none;
      span {
        &:first-child {
          position: absolute;
          top: 29px;
          left: 54%;
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
        }
        &:last-child{
          background: #6072ac;
        }
      }
      svg {
        width: 28px;
        height: 28px;
      }
      &:after {
        width: 100px;
        height: 100px;
        position:absolute;
        top:2px;
        left:6px;
        background: #6072ac;
        -webkit-transform: rotate(-4deg) skew(3deg);
        -ms-transform: rotate(-4deg) skew(3deg);
        transform: rotate(-4deg) skew(3deg);
        z-index: -1;
        content: '';
      }
    }
  }

  .search-box__fields-wrapper {
    width: 100%;
    padding-top: 4px;
    &:after{
      content:"";
      display:block;
      clear:both;
    }
  }

  .search-box__field-wrapper {
    padding: 0 25px 0 0;
    position: relative;
    border-bottom: 1px solid #fff;
    overflow: hidden;
  }
  &-label {
    float: left;
    padding: 6px 22px 0 0;
    label {
      font-size: 2rem;
      line-height: 1.2;
      color: #fff;
      font-weight: 700;
      padding: 0;
      margin: 0;
      display:inline-block;
      vertical-align:top;
      text-transform: uppercase;
    }
  }
  &-input {
    width: 100%;
    input {
      height: 35px;
      padding: 0;
      color: #b0b9d6;
      font-weight: 400;
      font-family: 'roboto condensed';
      font-size: 20px;
      line-height: 1.2;
      background: none;
      border: none;
      width: 100%;
      &:focus {
        outline-color: #fff;
        outline-offset: -1px;
      }
    }
  }
  .search-box__button {
    position:absolute;
    top:0;
    right: 0;
    height: 35px;
    width: 18px;
    overflow: hidden;
    &:after {
      position:absolute;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: icons-default;
      font-size: 16px;
      line-height: 1;
      color: #fff;
      content: '\e057';
    }
    &:focus {
      outline-color: #fff;
      outline-offset: -1px;
    }
  }
  .ddm__close {
    position:absolute;
    top: 4px;
    right: 5px;
    width: 40px;
    height: 40px;
    text-indent:-9999px;
    overflow:hidden;
    &:after,
    &:before {
      position:absolute;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
      transform: translate(-50%, -50%) rotate(-45deg);
      background: #fff;
      width: 1px;
      height: 24px;
      content: '';
    }
    &:after {
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:focus {
      outline-color: #fff;
      outline-offset: -1px;
    }
  }
  &-icons {
    position:absolute;
    top:55%;
    left:35px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

// @name Search list
.pertinence {
  font-size: 1.2em;
  span {
    font-size: em(1, 1.2);
  }
}
@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, .pertinence span {
      font-size: 1.1em;
    font-weight: $bold;
  }
}

meter {
  -webkit-appearance: meter;
  -moz-appearance: meterbar;
  width: em(6, 1.2);
  height: em(1, 1.2);
  border: 1px solid darken($color-3--1, 20%);
  &::-webkit-meter-bar {
    background: darken($color-3--1, 20%);
    height: 100%;
    width: 100%;
  }
  &::-webkit-meter-optimum-value {
    height: 100%;
    background: $color-1--2;
  }
  &:-moz-meter-optimum {
    background: darken($color-3--1, 20%);
    border: none;
  }
  &:-moz-meter-optimum::-moz-meter-bar {
    background: $color-1--2;
  }
  &:-moz-meter-sub-optimum::-moz-meter-bar {
    background: antiquewhite;
  }
  &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: steelblue;
  }
}
@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, meter {
    display: none;
  }
}

// @name Search list filtering
.facets {
    > li {
        margin-bottom: 3rem;
    }
}
.facet-option-list {
    a {
        color: $color-5--1;
    }
    > li {
        margin: 4px 0;
        font-size: 1.5rem;
    }
    .filter-solr-date {
        font-size: 1rem;
    }
}
#tx-solr-facets-available {
    .csc-header {
        > .title-3 {
            margin-top: 0;
        }
    }
}
