// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4.4;
.title-1 {
  font-family: $typo-3;
  font-size: #{$title--1__font-size}em;
  line-height: 1em;
  font-weight: $normal;
  text-transform: uppercase;
  margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
  font-family: $typo-3;
  font-size: #{$title--2__font-size}em;
  font-weight: $normal;
  text-transform: uppercase;
  margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
  font-family: $typo-3;
  font-size: #{$title--3__font-size}em;
  line-height: 1em;
  font-weight: normal;
  text-transform: uppercase;
  color: #201d1d;
  margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
  padding-bottom: em(0.8, $title--3__font-size);
  position: relative;
  &:after {
    background-color: #5090c8;
    display: block;
    content: '';
    width: 32px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 100%;
  }
}
.title-4 {
  margin-bottom: 1.2em;
  &:after {
    background-color: #572381;
  }
}

.title-5 {
  &:after {
    background-color: #ffdc00;
  }
}

.title-6 {
  &:after {
    background-color: #97b911;
  }
}