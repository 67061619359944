.list-type-1--event {
  .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
    display: table-cell;
    vertical-align: top;
  }
  .list-type-1__picture {
    float: none;
  }
  .list-type-1__wrapper-date {
    padding-right: 2em;
    width: 16em;
    border-right: 1px solid $color-3--3;
  }
  .list-type-1__wrapper {
    padding-left: 2em;
  }
  .list-type-1__category{
    a{
      color: #5090c8;
    }
  }
}
