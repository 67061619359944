.mosaic {
	&-title-box {
		padding: 0;
		.mosaic-title {
			font-size: 2.8rem;
		}
	}
	&-box1 {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}