// @name Main section heading
// @description Default css
.heading {
  .heading__wrapper {
    padding: 0 10px;
  }
  .heading__figure {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 2em;
  }
  // right image
  .heading__wrap + .heading__wrapper-figure {
    margin-top: 2em;
    .heading__figure {
      margin-left: 0;
    }
  }
  h1 {
    font-size: 3.5em;
    line-height: 1.2;
    color: $color-black;
  }
  .heading-holder {
    .figure-heading {
      margin-left: 0;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .heading-text {
    float: left;
    width: 100%;
  }
  &-buttons {
    padding: 0;
    a {
      width: 40px !important;
      height: 40px !important;
      margin: 0 0 0 1px; 
      padding: 0 !important;
    }
    .button-1--rss {
      padding: 1em 0.92857em !important;
    }
  }
}

// @name Heading single
// @dependency .heading
.heading--single {
  .heading__wrapper {
    flex-direction: column;
  }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
  .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
    display: block;
    vertical-align: inherit;
  }
  .heading-bottom {
    .all-dates__item {
      float: none;
      margin: 0 auto;
    }
  }
  .heading__wrapper-date {
    width: 100%;
    border-right: 0;
    display: table;
    border-bottom: 1px solid $color-3--3;
    margin-bottom: 2em;
    .date-1, .hour-place {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      padding-bottom: 2em;
    }
    .date-1 {
      text-align: left;
      time:nth-of-type(1) {
        margin-left: 0;
      }
    }
  }
  .heading__wrap {
    padding-left: 0;
  }
}
.section-main {
  .heading {
    padding-top: 0.5em;
  }
}

.decor-title {
  font-size: 2.8rem;
  &:after {
    width: 31px;
    height: 5px;
  }
}

h1 {
  font-size: 3.5rem;
  margin: 0 0 15px;
}