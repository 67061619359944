.filters {
  form {
    margin: 2em 0 0;
  }
}

.ddm {
  .filters__ddm {

  }
  .filters__ddm__sub-level {
    display: block;
    height: auto;
    overflow: hidden;
    max-height: 0;
    transition: max-height ease .2s;
  }
  &.ddm--active {
    .filters__ddm__sub-level {
      max-height: 100em;
      transition: max-height ease 1s;
    }
  }
}

.filters__wrapper {
  display: block;
  table-layout: inherit;
}

.filters__fields, .filters__button-wrapper {
  display: block;
  vertical-align: inherit;
}

.filters__fields {
  .filters__fields-wrapper {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    margin: 0;
    > .filters__field-wrapper {
      align-self: inherit;
      flex: inherit;
      margin-right: 0;
      margin-left: 0;
    }
  }
  &.filters__fields--column-2, &.filters__fields--column-3, &.filters__fields--column-4 {
    .filters__fields-wrapper {
      > .filters__field-wrapper {
        width: 100%;
      }
    }
  }
}

.filters__button-wrapper {
  padding-left: 0;
  padding-top: 0;
  text-align: right;
  width: 100%;
}

.heading-filters {
  position: relative;
  .heading {
    padding-right: 42px;
  }
  .ddm {
    position: static;
    &.ddm--active {
      .ddm__button {
        background: #572381;
        &:before {
          content: '\e008';
          font-size: 30px;
          color: #fff;
        }
      }
    }
  }
  .filters__ddm {
    position:absolute;
    top:21px;
    right: 11px;
    .ddm__button {
      width: 40px;
      height: 40px;
      padding: 0;
      z-index: 12;
      font-weight: 400;
      overflow: hidden;
    }
    &__sub-level {
      .filters__wrapper {
        padding-bottom: 10px;
      }
    }
  }
  .filters {
    padding: 0;
    &__button-wrapper {
      text-align: center;
    }
  }

  .heading__wrap .filters {
    .filters__ddm .ddm__button {
      position: relative;
      top: -16px;
    }
  }
}