// @name Cross menu
.menu-cross {
	width: 26.9%;
	padding: 0 5.4%;
	position: relative;
	float: right;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	 &:after {
      width: 1px;
      background: #8796c5;
      position:absolute;
      top:-999px;
      right: 0;
      bottom: -999px;
      content: '';
    }

	ul {
		margin: 0 0 28px;
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 1.2;
		text-transform: uppercase;
		li {
			margin: 0 0 8px;
			&.active {
				a {
					font-weight: 700;
				}
			}
		}
		a {
			color: #fff;
			position: relative;
			padding: 0 0 0 14px;
			&:after {
				font-family: icons-default;
				font-size: 12px;
				line-height: 1;
				position: absolute;
				top: 50%;
				left: 0;
				-webkit-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				transform: translate(0, -50%);
				font-weight: 400;
				content: '\e027';
			}
		}
	}
}