// @name Go so far
// @description Sections content below the content

.go-so-far {
  background: $color-3--1;
  padding: 4em 0;
}
.go-so-far__title {
  margin-top: 0;
  font-size: 30px;
  color: #201d1d;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 45px;
  &:after {
    background-color: #5090c8;
    display: block;
    content: '';
    width: 36px;
    height: 6px;
    position: absolute;
    left: 0;
    top: 100%;
  }
}
.go-so-far__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -2%;
  padding-left: 113px;
  > * {
    flex: 2;
    margin: 0 2%;
    &:last-child {
      flex: 2;
    }
  }
}
.go-so-far__item {
  & + .go-so-far__item {
    padding-left: 53px;
  }
}