// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.content {
  // @name Table
  .table-wrapper {
    position: relative;
  }
  .table-wrapper-fade {
    position: absolute;
    right: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,.8) 100%);
  }
  .table-wrapper-inner {
    max-width: 100%;
  }
  table {
    caption {
      text-align: left;
    }
  }
}

.files-content {
  padding: 30px 15px;
  .file-column {
    & + .file-column {
      padding-left: 20px;
    }
  }
}

