.side-menu {
  position: fixed !important;
  top: 0;
  right: 0;
  height: 100%;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  width: 310px;
  background: #572381;
  z-index: 200;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  overflow-y: auto;
  -webkit-transition:-webkit-transform 0.3s linear;
  transition:transform 0.3s linear;
  &-opened {
    .side-menu {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  &-inner {
    overflow-y: auto;
    padding: 20px 30px 20px 40px;
    position: relative;
    width: 100%;
  }
  &-title {
    color: #fff;
    font-size: 2.2rem;
    line-height: 1.2;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
    margin: 0 0 30px;
    padding-bottom: 20px;
    padding-right: 30px;
    position: relative;
    &:after {
      background: #97b911;
      display: block;
      content: "";
      width: 32px;
      height: 5px;
      position: absolute;
      left: 0;
      top: 100%;
    }
    .icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      font-weight: normal;
      position: relative;
      @include default-icons-absolute-before("\e000", $size: 18px, $color: #fff, $left: 0, $top: 0);
    }
    .plus {
      font-size: 3rem;
      line-height: 1;
      vertical-align: -2px;
      font-family: arial;
    }
  }
  &-list {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    li {
      margin: 0 0 15px;
      position: relative;
      padding-left: 15px;
      @include default-icons-absolute-before("\e027", $size: 12px, $color: #5090c8, $left: 0, $top: 50%);
      &:before {
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    a {
      color: #fff;
      &:focus {
        outline-color:#fff;
        outline-offset: -1px;
      }
    }
  }
  &-ico {
    position:absolute;
    top:25px;
    left:32px;
  }
  &-opener {
    position: relative;
    display: block;
    background: url(../Images/bg/btn-side-menu.png) 0 0 no-repeat;
    width: 110px;
    height: 121px;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 700;
    padding: 6px 5px 14px 17px;
   strong {
     display: block;
     font-size: 15px;
     margin-bottom: 8px;
   }
   .plus {
    display:inline-block;
    vertical-align:top;
    font-size: 4.2rem;
    line-height: 0.6;
    margin-bottom: 8px;
    font-family: arial;
   }
    .icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-bottom: 8px;
      overflow: hidden;
      text-indent: -9999px;
      @include default-icons-absolute-before("\e000", $size: 25px, $color: #fff, $left: 0, $top: 0);
      &:before {
        text-indent: 0;
      }
    }
    em {
      display: block;
      font-size: 12px;
      font-style: normal;
    }
    &:focus {
      outline-offset:-1px;
      outline-color: #fff;
    }
    & > * {
      background: #641e84;
    }
  }
  &-close {
    position:absolute;
    top: 13px;
    right: 14px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    &:focus {
      outline-offset:-1px;
      outline-color: #fff;
    }
    &:after,
    &:before {
      position:absolute;
      top:50%;
      left:50%;
      width: 24px;
      height: 1px;
      background: #fff;
      -webkit-transform: translate(-50%, -50%) rotate(-43deg);
      -ms-transform: translate(-50%, -50%) rotate(-43deg);
      transform: translate(-50%, -50%) rotate(-43deg);
      content: '';
    }
    &:before {
      -webkit-transform: translate(-50%, -50%) rotate(43deg);
      -ms-transform: translate(-50%, -50%) rotate(43deg);
      transform: translate(-50%, -50%) rotate(43deg);
    }
  }
}
.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(54,64,64,0.6);
  z-index: 100;
}

