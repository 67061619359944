// @name Liste type 3
// @description Directory list
.list-type-3__listitems {
  .list-paginated__wrapper {
    flex-direction: column;
    margin: 0;
    display: block;
    > * {
      flex: 0 1 100%;
      max-width: inherit;
      margin: 0 0 3em;
    }
  }
}