.kiosk {
	position: relative;
	padding-bottom: 82px;
	&-wrap {
		margin: 0;
	}
	&-item {
		padding: 0 0 28px;
		width: 100%;
		float: none;
		overflow: hidden;
		&-wrap {
			width: 100%;
			margin: 0 0 14px;
		}
		&-img {
			width: 138px;
			margin: 0 10px 0 0;
			float: left;
		}
		&-content {
			padding: 0;
		}
		&-title {
			font-size: 2.6rem;
			line-height: 1;
			margin: 0 0 2px;
		}
		&-subtitle {
			font-size: 2.6rem;
			padding: 0 0 10px;
			margin: 0 0 28px;
		}
	}
	.newsletter {
		float: none;
	}
}