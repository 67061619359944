.logo {
	float: left;
	width: 293px;
	margin: -18px 0 0 -24px;
	a {
		display: block;
	}
	img {
		display: block;
		width: 100%;
		height: auto;
	}
	@media (min-width: 961px) and (max-width: 1170px) {
		width: 266px;
		margin: -9px 0 0 -24px;
	}
}