.home {
	.header-block {
		min-height: 703px;
	}
}

.header-block {
	min-height: 450px;
	position: relative;
	padding-top: 101px;
	&-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			min-height: 100%;
			min-width: 101%;
			width: auto;
			height: auto;
			max-width: none;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
	&-description {
		background: url(../Images/bg/bg-decor-03.png) no-repeat;
		width: 549px;
		height: 246px;
		padding: 69px 50px 50px 86px;
		position:absolute;
		bottom: 151px;
		right: 0;
		&:hover,&:focus {
			text-decoration: none;
			background: url(../Images/bg/bg-decor-03-h.png) no-repeat;
		}
		&-purple {
			background: url(../Images/bg/bg-decor-07.png) no-repeat;
			&:hover {
				background: url(../Images/bg/bg-decor-07-h.png) no-repeat;
			}
		}
		&-red {
			background: url(../Images/bg/bg-decor-08.png) no-repeat;
			&:hover {
				background: url(../Images/bg/bg-decor-08-h.png) no-repeat;
			}
		}
	}
	&-title {
		font-size: 3rem;
		line-height: 1;
		color: #fff;
		font-weight: 400;
		text-transform: uppercase;
		font-style: italic;
	}
	&-content {
		position:absolute;
		bottom: -10px;
		left:50%;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
		max-width: 550px;
		width: 100%;
		z-index: 2;
	}
	&-search {
		background: #fff;
		padding: 0 80px 0 0;
		position: relative;
		margin: 0 0 25px;
		form {
			margin: 0;
		}
		.search-box {
			&__button-wrapper {
				position:absolute;
				top:0;
				right: 0;
			}
			&__button {
				width: 80px;
				height: 56px;
				border: none;
				background: none;
				position: relative;
				overflow: hidden;
				z-index: 1;
				&:after {
					width: 80px;
					height: 80px;
					position:absolute;
					top:1px;
					left:14px;
					background: #6072ac;
					-webkit-transform: rotate(-10deg) skew(10deg);
					-ms-transform: rotate(-10deg) skew(10deg);
					transform: rotate(-10deg) skew(10deg);
					z-index: -1;
					content: '';
				}
				svg {
					position: relative;
					margin: 2px 0 0 3px;
				}
			}
			&__field-wrapper {
				input {
					color: #777;
					font-family: 'roboto condensed';
					font-weight: 300;
					font-size: 16px;
					height: 56px;
					padding: 0 28px;
					width: 100%;
					border: none;
					background: none;
					font-style: italic;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
