.news-main {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	margin: -20px 0 12px;
	padding: 20px 0;
	text-decoration: none;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	&:hover,
	&:focus {
		text-decoration: none;
		.news-main-title {
			color: #97b911;
		}
	}
	&:hover {
		.news-main-img {
			&:after {
				opacity: 1;
			}
		}
	}
	&-img {
		width: 60%;
		overflow: hidden;
		position: relative;
		&:after {
			position:absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.5);
			opacity: 0;
			-webkit-transition:opacity 0.2s linear;
			transition:opacity 0.2s linear;
			content: '';
		}
	}
	&-description {
		width: 40%;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		z-index: 2;
		padding: 25px 61px 40px;
		&:after {
			position:absolute;
			top:0;
			left:-2.1%;
			right: -10px;
			bottom: 10px;
			background: #572381;
			z-index: -1;
			-webkit-transform: rotate(2deg) skew(5deg);
			-ms-transform: rotate(2deg) skew(5deg);
			transform: rotate(2deg) skew(5deg);
			content: '';
		}
		&:before {
			position:absolute;
			height: 50px;
			left: -0.3%;
			right: -10px;
			bottom: -5px;
			background: #572381;
			z-index: -1;
			-webkit-transform: rotate(-1deg) skew(2deg);
			-ms-transform: rotate(-1deg) skew(2deg);
			transform: rotate(-1deg) skew(2deg);
			content: '';
		}
	}
	&-category {
		color: #572381;
		font-size: 1.4rem;
		line-height: 1.2;
		background: #97b911;
		text-align: right;
		min-width: 150px;
		display:inline-block;
		vertical-align:top;
		padding: 7px 22px;
		margin: 0 0 10px -87px;
		text-transform: uppercase;
		font-style: italic;
	}
	&-title {
		font-size: 3.6rem;
		line-height: 1;
		color: #fff;
		font-weight: 700;
		margin: 0 0 8px;
		width: 100%;
		display: block;
	}
	p {
		font-size: 1.6rem;
		line-height: 1.2;
		color: #fff;
		margin: 0 0 10px;
		font-weight: 300;
	}
}