.option-bar,
.content .option-bar {
	margin: 0;
	margin-top: 19px;
	&-indent {
		margin-top: 15px;
	}
	li {
		float: left;
		margin: 0 0 0 34px;
		padding: 0;
		&:before {
			display: none;
		}
	}
	a {
		display:inline-block;
		vertical-align:top;
		padding: 0 0 0 21px;
		color: #572381;
		font-size: 1.4rem;
		line-height: 1.2;
		position: relative;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: none;
		&:after {
			position:absolute;
			top:50%;
			left:0;
			font-family: icons-default;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
			font-size: 12px;
			font-weight: 400;
			line-height: 1;
			content: '';
		}
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	.option-bar-rss {
		&:after {
			content: '\e02c';
		}
	}
	.option-bar-plus {
		&:after {
			content: '\e000';
		}
	}
	.option-bar-volume {
		&:after {
			background: url(../Images/pictos/ico-volume.svg) no-repeat;
			background-size: 16px 14px;
			width: 16px;
			height: 14px;
		}
	}
	&-white {
		a {
			color: #fff;
		}
	}
	&-black {
		a {
			color: #000;
		}
	}
	&-green {
		a {
			color: #97b911;
		}
	}
}