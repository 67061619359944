.newsletter {
	width: 100%;
	overflow: hidden;
	.btn-valide {
		width: auto;
		margin: 0 auto;
		padding: 15px 30px;
	}
	form {
		margin: 0 0 11px;
	}
	&__links {
		margin: 0;
		text-align: center;
		max-width: 170px;
		margin: 0 auto;
	}
}