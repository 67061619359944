// @name Document list
// @description List of document in publications list

.list-document {

}
.list-document__listitems {
  flex-direction: column;
  > * {
    flex: 0 0 1;
  }
}