.heading-block {
	margin: 0 0 30px;
	&:after{
		content:"";
		display:block;
		clear:both;
	}
	.decor-title,
	h2 {
		float: left;
		margin: 0;
	}
	.option-bar {
		float: right;
	}
}