// @name Pager list
.pager,
.content .pager {
  @extend .clear-fix;
  ul {
    font-family: $typo-2;
    clear: both;
    display: block;
    margin: 3em 0 5em 0;
    padding: 0;
    font-size: inherit;
    text-align: center;
    font-weight: $light;
    font-style: normal;
    text-transform: uppercase;
  }
  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 em(0, 1.4);
    padding: 0;
    background: none;
    font-size: 1.4em;
    margin-top: 3px;
    &:before {
      display: none;
    }
    a, span {
      padding: em(1, 1.4) em(1.4, 1.4);
      line-height: 1em;
      display: inline-block;
      vertical-align: middle;
    }
    a {
      color: $color-black;
      background: $color-3--1;
      text-decoration: none;
      transition: all ease .2s;
      font-weight: $light;
      &:hover, &:focus {
        color: color-contrast($color-1--2);
        background: $color-1--2;
      }
    }
    span {
      cursor: default;
      color: $color-white;
      font-weight: $bold;
      background: $color-2--2;
    }
    &.pager__prev, &.pager__next {
      a, span {
        padding: 13px 25px;
        font-weight: 300;
      }
      // Inactive
      span {
        color: color-contrast($color-3--1);
        background: $color-3--1;
      }
    }
    &.pager__prev {
      margin: 0;
      float: left;
      a, span {
        float: left;
        @include default-icons-before('\e026', 0 em(.5, 1.4) 0 0, 14px, #5090c8, em(-.2, 1.4));
        &:hover:before, &:focus:before {
          color: #fff;
        }
      }
      span {
        color: $color-3--2;
        // Inactive
        &:before, &:hover:before, &:focus:before {
          color: $color-3--2;
        }
      }
    }
    &.pager__next {
      margin: 0;
      float: right;
      a, span {
        float: right;
        @include default-icons-after('\e027', 0 0 0 em(.5, 1.4), 14px, #5090c8, em(-.2, 1.4));
        &:hover:after, &:focus:after {
          color: #fff;
        }
      }
      span {
        color: $color-3--2;
        // Inactive
        &:after, &:hover:after, &:focus:after {
          color: $color-3--2;
        }
      }
    }
  }
}

// @name Pager infinite scroll

@keyframes spin {
  0% {
    transform: rotate(0deg) translateY(-50%);
  }
  100% {
    transform: rotate(-360deg) translateY(-50%);
  }
}

.pager-infinite,
.content .pager-infinite {
  margin: 2em 0;
  text-align: center;
  // @dependence .button-2
  .pager-infinite__button {
    &:before {
      content: "\e05f";
      left: 0.5em;
    }
  }
  .pager-infinite__loading {
    &:before {
      animation: spin 1s infinite linear;
      content: "\e05f";
      transform-origin: top;
    }
  }
}

// @name Pager single
// @dependence .pager
.pager-single,
.content .pager-single {
  .pager-single__back-to-list {
    margin: 0;
    a {
      background: $color-2--2;
      font-weight: $bold;
      color: $color-white;
      padding: 13px 20px;
      @include default-icons-before('\e02f', 0 em(.5, 1.4) 0 0, em(1.2, 1.4), $color-white, em(-.2, 1.4));
    }
  }
}
.powermail_create{
  .pager-single__back-to-list{
    background: $color-2--2;
    font-weight: $bold;
    color: $color-white;
    padding: 13px 20px;
    text-decoration:none;
    @include default-icons-before('\e02f', 0 em(.5, 1.4) 0 0, em(1.2, 1.4), $color-white, em(-.2, 1.4));
  }
}
// @name Pager single title
// TODO


// @name Pager alphabet
.pager-alphabet,
.content .pager-alphabet {
  margin-bottom: 5em;
}
.pager-alphabet__title,
.content .pager-alphabet__title {
  font-size: 1.4em;
  font-weight: $bold;
  margin-bottom: em(1.5, 1.4);
}
.pager-alphabet__listitems,
.content .pager-alphabet__listitems {
  text-align: left;
  > li {
    display: inline-block;
    vertical-align: middle;
    margin: .2em 0.1em;
    &.pager-alphabet__item--current {
      > a {
        background: $color-2--2;
        color: $color-white;
        font-weight: $bold;
      }
    }
    > a, > span {
      $pager-alphabet__listitems__a__font-size: 1.4;
      font-size: #{$pager-alphabet__listitems__a__font-size}em;
      background: $color-white;
      color: $color-black;
      display: block;
      font-weight: $light;
      padding: em(.6, $pager-alphabet__listitems__a__font-size) em(1.1, $pager-alphabet__listitems__a__font-size);
    }
    > a {
      &:hover, &:focus {
        background: $color-1--2;
        color: $color-white;
        text-decoration: none;
      }
    }
    > span {
      background: $color-white;
      border: 1px solid darken($color-3--1, 3%);
      color: darken($color-3--1, 15%);
    }
  }
}

