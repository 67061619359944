.waste-management {
  $this: &;
  $filter: '.filters';

  &__heading {
    padding: 3rem 2rem 2.5rem;
  }

  &__filter {
    #{$filter}__button-wrapper {
      padding: 0;
      text-align: center;
    }
  }

  &__main {
    padding: 3rem 1rem;
  }

  &__zone {
    .ce-column {
      margin: 1.5rem;

      &:last-child {
        margin: 1.5rem;
      }
    }
  }

  &__footer {
    margin-top: 4rem;
  }
}
