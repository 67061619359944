// @name Breadcrumb
.breadcrumb {
  $breadcrumb__font-size: 1.2;
  font-family: $typo-2;
  display: inline-block;
  vertical-align: middle;
  width: 70%;
  p {
    font-size: #{$breadcrumb__font-size}em;
    color: #000;
    .breadcrumb__here {
      color: $color-3--3;
    }
    a {
      font-weight: $normal;
      color: #000;
      margin-right: 6px;
      @include default-icons-after('\e027', 0 0 0 9px, em(1, $breadcrumb__font-size), #000);
      &:last-child {
        &:after {
          color: #572381;
        }
      }
    }
  }
}