.menu-cross {
	margin: 0 -10px;
	padding: 19px 10px 0;
	border-bottom: 1px solid #8796c5;
	float: none;
	width: auto;
	ul {
		width: 140px;
		margin: 0 auto;
		padding: 7px 0 15px;
	}
}