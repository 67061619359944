.list-event-info {
  overflow: hidden;
  font-size: 10px;
  font-weight: 300;
  color: #1d2f3e;
  li {
    float: left;
    margin: 0 20px 10px 0;
  }
  a {
    color: #1d2f3e;
    svg {
      float: left;
      margin-right: 10px;
    }
    span {
      padding-top: 5px;
      float: left;
    }
    &.ico-pay {
        svg {
          width: 21px;
          height: 21px;
        }
    }
    &.ico-reserv {
      svg {
        width: 13px;
        height: 21px;
      }
    }
    &.ico-part {
      svg {
        width: 28px;
        height: 21px;
      }
    }
    &.ico-june {
      svg {
        width: 27px;
        height: 21px;
      }
    }
    &.ico-grat {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}