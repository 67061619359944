.list-type-2--publications {
    .list-type-2__picture--2 {
        padding-bottom: 0;
        span {
            border: 1px solid #f0f0f0;
            display: block;
            margin-bottom: 1em;
        }
        img {
            background-color: rgba(0, 0, 0, .3);
            opacity: .75;
            &:hover {
                opacity: 1;
            }
        }
    }
    .list-type-2__item {
        &:hover {
            img {
                opacity: 1;
            }
        }
    }
}
