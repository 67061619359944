// @name Main menu
.home .menu-main {
	&__level-1 {
		> li {
			> a {
				padding: 43px 23px 44px 35px;
			}
		}
	}
	@media (min-width: 961px) and (max-width: 1170px) {
		&__level-1 {
			> li {
				> a {
					padding: 43px 11px 44px 27px;
					font-size: 1.6rem;
					&:before {
						left: 11px;
					}
				}
			}
		}
	}
}

.menu-main {
	float: right;
	&__level-1 {
		float: left;
		> li {
			float: left;
			margin: 0 0 0 -1px;
			&.active,
			&.focus {
				> a {
					background: #572381;
					color: #fff;
					z-index: 2;
					&:after {
						display: none;
					}
				}
				.menu-main__drop {
					display: block;
				}
			}
			&:first-child {
				> a {
					&:after {
						display: none;
					}
				}
			}
			> a {
				display: block;
				padding: 43px 17px 44px 35px;
				color: #000;
				text-decoration: none;
				text-transform: uppercase;
				font-size: 1.7rem;
				line-height: 1.2;
				font-weight: 700;
				position: relative;
				&:after {
					position:absolute;
					top:50%;
					left:0;
					width: 1px;
					height: 32px;
					background: #ccc;
					-webkit-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					transform: translate(0, -50%);
					content: '';
				}
				&:before {
					position:absolute;
					top:50%;
					left:17px;
					-webkit-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					transform: translate(0, -50%);
					border-top: 6px solid #6072ac;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					content: '';
				}
				&:hover,
				&:focus {
					background: #572381;
					color: #fff;
					z-index: 2;
					&:after {
						display: none;
					}
				}
				&:focus {
					outline-color: #fff;
					outline-offset: -1px;
				}
			}
			.active{
				background: #572381;
				color: #fff;
				z-index: 2;
				&:after {
					display: none;
				}
			}
		}
	}
	&__drop {
		position:absolute;
		top:100%; 
		left:0;
		width: 100%;
		background: #572381;
		padding: 64px 56px 35px;
		display: none;
		z-index: 10;
		&-wrap {
			display: flex;
			flex-wrap: wrap;
		}
		&-col {
			width: 25%;
			padding: 0 15px 27px;
		}
		&-title {
			color: #fff;
			font-size: 1.8rem;
			line-height: 1.2;
			font-weight: 700;
			display: block;
			margin: 0 0 12px;
			text-decoration: none;
			text-transform: uppercase;
			a {
				text-decoration: none;
				position: relative;
				display: block;
				color: #fff;
				overflow: hidden;
				padding: 5px 0 8px;
				&:after {
					background: #fff;
					width: 250px;
					height: 3px;
					position:absolute;
					bottom: 0;
					left:0;
					-webkit-transform: translate(-218px, 0);
					-ms-transform: translate(-218px, 0);
					transform: translate(-218px, 0);
					-webkit-transition:-webkit-transform 0.3s linear;
					transition:transform 0.3s linear;
					content: '';
				}
				&:hover,
				&:focus {
					background: #81a341;
					&:after {
						-webkit-transform: translate(0, 0);
						-ms-transform: translate(0, 0);
						transform: translate(0, 0);
					}
				}
				&:focus {
					outline-color: #fff;
					outline-offset: -1px;
				}
			}
		}
		&-title-active{
			a {
				background-color:#81a341;
				padding: 10px 15px;
				&:after {
					background: none;
				}
			}
		}
		&-list {
			font-size: 1.4rem;
			line-height: 1.2;
			font-weight: 300;
			margin: 0 0 0 -10px;
			li {
				margin: 0 0 5px;
				&.active3 {
					a {
						background: #81a341;
						text-decoration: none;
					}
				}
			}
			a {
				color: #fff;
				position: relative;
				padding: 4px 10px 4px 24px;
				display: block;
				text-transform: uppercase;
				&:after {
					position:absolute;
					top:8px;
					left:10px;
					border-left: 6px solid #fff;
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;
					content: '';
				}
				&:hover,
				&:focus {
					background: #81a341;
					text-decoration: none;
				}
				&:focus {
					outline-color: #fff;
					outline-offset: -1px;
				}
			}
		}
		&-close {
			width: 40px;
			height: 40px;
			position:absolute;
			top:10px;
			right: 18px;
			border: none;
			background: none;
			text-indent:-9999px;
			overflow:hidden;
			&:after,
			&:before {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%) rotate(-45deg);
				-ms-transform: translate(-50%, -50%) rotate(-45deg);
				transform: translate(-50%, -50%) rotate(-45deg);
				width: 2px;
				height: 20px;
				background: #fff;
				content: '';
			}
			&:after {
				-webkit-transform: translate(-50%, -50%) rotate(45deg);
				-ms-transform: translate(-50%, -50%) rotate(45deg);
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:focus {
				outline-color: #fff;
				outline-offset: -1px;
			}
		}
	}
	@media (min-width: 961px) and (max-width: 1170px) {
		&__level-1 {
			> li {
				> a {
					padding: 44px 8px 45px 21px;
					font-size: 1.5rem;
					&:before {
						left: 8px;
					}
				}
			}
		}
	}
}
