.option-bar {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	li {
		padding: 0 5px 23px;
		margin: 0;
		&:nth-child(3) {
			width: 100%;
			text-align: center;
		}
	}
}