// @name bloc news
.bloc-news {
  .category {
    color: #572381;
  }
}

// @name Bloc news in column
.column-bloc.bloc-news {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1.2em;
  }
  .bloc-news__title {
    $bloc-news__title__font-size: 2;
    line-height: 1.2;
    font-family: $typo-3;
    font-weight: normal;
    font-size: #{$bloc-news__title__font-size}em;
    margin: 0 0 em(1.5, $bloc-news__title__font-size);
    color: $color-black;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
}