.image-gallery {
	padding-top: 3px;
	&-block {
		padding: 30px 0;
	}
	.listItems {
		display: flex;
	}
	&-sm {
		display: flex;
		flex-wrap: wrap;
		width: 16.66%;
		.btn-play {
			width: 32px;
			height: 32px;
			&:after {
				border-width: 5px 0 5px 8px;
			}
		}
	}
	&-lg {
		height: 320px;
		width: 33.32%;
		.item {
			height: 100%;
		}
	}
	.item {
		width: 100%;
		position: relative;
		overflow: hidden;
		a {
			display: block;
			height: 100%;
			img {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: auto;
				height: auto;
				min-width: 101%;
				min-height: 100%;
				max-width: none;
			}
			&:hover,
			&:focus {
				.image-gallery-content {
					-webkit-transform: translate(0, 0);
					-ms-transform: translate(0, 0);
					transform: translate(0, 0);
				}
			}
		}
	}
	&-content {
		position:absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.5);
		-webkit-transform: translate(0, 100%);
		-ms-transform: translate(0, 100%);
		transform: translate(0, 100%);
		-webkit-transition:-webkit-transform 0.2s linear;
		transition:transform 0.2s linear;
	}
	&-title {
		font-size: 20px;
		line-height: 1.2;
		color: #fff;
		position:absolute;
		top:50%;
		left:0;
		width: 100%;
		padding: 0 10px;
		text-align: center;
		text-transform: uppercase;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
	
	}
}