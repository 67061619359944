.heading-block {
	.option-bar {
		position:absolute;
		bottom: 18px;
		left:0;
		width: 100%;
		height: 32px;
		&_centered {
			width: 100%;
			display: block !important; 
			text-align: center;
			li {
				display: inline-block;
				vertical-align: middle;
				margin-left: 0;
				float: none;
			}
		}
	}
}