// @name buttons
// @name button-1 (light)
// @state button-1--big - Big button-1
// @state button-1--small - Small button-1
// @state .button-1--no-icon - Button-1 without icon
// @state .button-1--svg - Button-1 with svg content
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view
// @state .button-column-2 wrap buttons 2 colomn
$button-1__font-size: 1.4;
$button-1--big__font-size: 1.9;
$button-1--small__font-size: 1.2;
input.button-1,
.content input.button-1 {
  padding-left: em(3.2, $button-1__font-size);
  &.button-1--big {
    padding-left: em(3.2, $button-1--big__font-size);
  }
  &.button-1--small {
    padding-left: em(2.2, $button-1--small__font-size);
  }
}

.powermail_form .button-column-2 > div {
  width: 50%;
  float: left;
}

.button-1,
.content .button-1 {
  font-family: $typo-2;
  display: inline-block;
  vertical-align: top;
  transition: all ease .3s;
  text-decoration: none;
  font-weight: $normal;
  color: $color-black;
  height: auto;
  text-transform: uppercase;
  background: $color-3--1;
  font-size: #{$button-1__font-size}em;
  line-height: em(1, $button-1__font-size);
  padding: 16px 20px 14px 38px;
  @include default-icons-absolute-before('\e026', em(1.1, $button-1__font-size), $color-1--2, 50%, inherit, inherit, em(2.2, 1.1));
  &:before {
    transform: translateY(-50%);
    transition: all ease .3s;
    font-weight: 400;
  }
  &[type=reset] {
    &:before {
      content: "\e002";
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background: $color-1--2;
    color: color-contrast($color-1--2);
    &:before {
      color: color-contrast($color-1--2);
    }
    svg {
      fill: color-contrast($color-1--2);
    }
  }
  // Big
  &.button-1--big {
    // Change font size reference
    font-size: #{$button-1--big__font-size}em;
    padding: em(1.9, $button-1--big__font-size) em(4, $button-1--big__font-size) em(1.9, $button-1--big__font-size) em(5, $button-1--big__font-size);
    &:before {
      left: em(3.5, $button-1--big__font-size);
    }
  }
  // Small
  &.button-1--small {
    // Change font size reference
    font-size: #{$button-1--small__font-size}em;
    padding: em(1.2, $button-1--small__font-size) em(2.4, $button-1--small__font-size) em(1.2, $button-1--small__font-size) em(3, $button-1--small__font-size);
    &:before {
      left: em(2, $button-1--small__font-size);
    }
  }
  // No icon
  &.button-1--no-icon {
    padding-left: em(2, $button-1__font-size);
    padding-right: em(2, $button-1__font-size);
    &:before {
      content: "";
      margin: 0;
    }
  }
  // No text
  &.button-1--no-text {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: em(1.3, $button-1__font-size) em(1.9, $button-1__font-size);
    &:before {
      text-indent: 0;
      line-height: 0;
      display: block;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      margin: 0;
    }
  }
  // With svg
  &.button-1--svg {
    padding: em(1.4, $button-1__font-size) em(1.3, $button-1__font-size);
  }
  svg {
    fill: $color-2--2;
    width: em(1.2, $button-1__font-size);
    height: em(1.2, $button-1__font-size);
    vertical-align: middle;
  }
  // With calendar font-icon
  &.button-1--calendar {
    &:before {
      left: em(1, 1.4);
      content: "\e05c";
    }
  }
  &.filters__locate {
    background: #fff;
    &:hover,
    &:focus {
      text-decoration: none;
      background: $color-1--2;
      color: color-contrast($color-1--2);
      &:before {
        color: color-contrast($color-1--2);
      }
      svg {
        fill: color-contrast($color-1--2);
      }
    }
  }
}

//  @name Extenral link for button-1
a.button-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1[href^="//"]:not([href*="#{$domain}"]) {
  @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-black, middle);
  &:after {
    transition: all ease .3s;
  }
  &:hover,
  &:focus {
    &:after {
      color: $color-white;
    }
  }
}

// @name button-2 (Dark)
// @state button-2--big - Big button-2
// @state button-2--small - Small button-2
// @state .button-2--no-icon - Button-2 without icon
// @state .button-2--svg - Button-2 with svg content
// @state .button-2--no-text-in-mobile - Indicates button-2 without content text in smartphone view
$button-2__font-size: 1.4;
$button-2--big__font-size: 1.9;
$button-2--small__font-size: 1.2;
input.button-2,
.content input.button-2 {
  padding-left: em(3.2, $button-2__font-size);
  &.button-2--big {
    padding-left: em(4, $button-2--big__font-size);
  }
  &.button-2--small {
    padding-left: em(2.4, $button-2--small__font-size);
  }
}

.button-2,
.content .button-2 {
  font-family: $typo-2;
  display: inline-block;
  vertical-align: top;
  transition: all ease .3s;
  text-decoration: none;
  font-weight: $bold;
  color: $color-white;
  text-transform: uppercase;
  background: $color-2--2;
  height: auto;
  font-size: #{$button-2__font-size}em;
  line-height: em(1, $button-2__font-size);
  padding: 16px 23px 14px 42px;
  @include default-icons-absolute-before('\e057', em(1.1, $button-2__font-size), $color-white, 50%, inherit, inherit, 23px);
  &:before {
    transform: translateY(-50%);
    transition: all ease .3s;
    font-weight: 400;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background: $color-1--2;
    color: color-contrast($color-1--2);
    &:before {
      color: $color-white;
    }
    svg {
      fill: color-contrast($color-1--2);
    }
  }
  // Big
  &.button-2--big {
    // Change font size reference
    font-size: 1.6em;
    padding: 21px 20px;
    &:before {
      left: em(3.5, $button-2--big__font-size);
    }
    &.button-2_blue {
      background: #5090c8;
      &:hover {
        background-color: darken(#5090c8, 10%);
      }
    }
    &.button-2_purple {
      background: #572381;
      &:hover {
        background-color: darken(#572381, 10%);
      }
    }
    &.button-2_green {
      &:hover {
        background-color: darken(#97b911, 10%);
      }
    }
  }
  // Small
  &.button-2--small {
    // Change font size reference
    font-size: #{$button-2--small__font-size}em;
    padding: em(1.2, $button-2--small__font-size) em(2.4, $button-2--small__font-size) em(1.2, $button-2--small__font-size) em(3, $button-2--small__font-size);
    &:before {
      left: em(2, $button-2--small__font-size);
    }
  }
  // No icon
  &.button-2--no-icon {
    padding-left: em(2, $button-2__font-size);
    padding-right: em(2, $button-2__font-size);
    &:before {
      content: "";
      margin: 0;
    }
  }
  // No text
  &.button-2--no-text {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: em(1, $button-2__font-size) em(1.5, $button-2__font-size);
    &:before {
      text-indent: 0;
      line-height: 0;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      margin: 0;
      font-size: em(1.4, $button-2__font-size);
    }
  }
  // With svg
  &.button-2--svg {
    padding: em(1.4, $button-2__font-size) em(1.3, $button-2__font-size);
  }
  svg {
    fill: $color-white;
    width: em(1.2, $button-2__font-size);
    height: em(1.2, $button-2__font-size);
    vertical-align: middle;
  }
  // With next icon
  &.button-2--next {
    &:before {
      content: "\e027";
    }
  }
  // With calendar font-icon
  &.button-2--calendar {
    &:before {
      left: em(1, 1.4);
      content: "\e05c";
    }
  }
  &.button-liste {
    display: block;
    margin-top: 52px;
    text-align: center;
  }

  &.button-2--purple {
    background-color: $color-1--2;

    &:hover,
    &:focus {
      background-color: $color-2--2;
    }
  }
}

.button-3 {
  display: block;
  font-size: 1.4rem;
  line-height: 1.2;
  color: #fff;
  padding: 13px 20px;
  text-transform: uppercase;
  background: #97b911;
  border: none;
  font-weight: 700;
  font-family: 'roboto condensed';
  &:hover,
  &:focus {
    background: #572381;
  }
}

//  @name Extenral link for button-2
a.button-2[href^="http://"]:not([href*="#{$domain}"]),
a.button-2[href^="https://"]:not([href*="#{$domain}"]),
a.button-2[href^="//"]:not([href*="#{$domain}"]) {
  @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-white, middle);
  &:after {
    transition: all ease .3s;
  }
}

// @name Grey form
// @description Form with grey background
.form--3 {
  // @name button-1 (light)
  .button-1 {
    background: $color-white;
    &:hover,
    &:focus {
      background: $color-1--2;
      text-decoration: none;
    }
  }
  // @name button-1 (light)
  .button-1 {
    background: $color-white;
    &:hover,
    &:focus {
      background: $color-1--2;
      text-decoration: none;
    }
  }
}

// @name max-height 100% form
// @description Form with max-height 100% and overflow auto
.form--overflow {
  max-height: 100%;
  overflow-y: auto;
}

.button {
  display: inline-block;
  background-color: #5090c8;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  padding: 13px 29px 13px 50px;
  cursor: pointer;
  margin-bottom: 9px;
  @include default-icons-absolute-before("\e034", $size: 12px, $color: #fff, $left: 30px);
  &:before {
    font-weight: normal;
  }
  &.button-contact {
    background-color: #97b911;
    &:before {
      content: "\e02a";
    }
    &:hover {
      background-color: darken(#97b911, 10%);
    }
  }
  &.button-inter {
    background-color: #572381;
    &:hover {
      background-color: darken(#572381, 10%);
    }
  }
  &.button-green {
    background-color: #97b911;
    font-size: 11px;
    padding: 15px 20px 11px 44px;
    &:before {
      left: 24px;
    }
    &:hover {
      background-color: darken(#97b911, 10%);
    }
  }
  &:hover {
    background-color: darken(#5090c8, 10%);
  }
  &.button-paiment {
    background: #5090c8;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    text-decoration: none;
    padding: 12px 24px 12px 50px;
    margin-bottom: 25px;
    &:before {
      background: url("../Images/content/ico-paiment.png") 0 0 no-repeat;
      content: '';
      width: 16px;
      height: 12px;
      top: 13px;
      left: 21px;
    }
    &:hover {
      background-color: darken(#5090c8, 10%);
    }
  }
}

.button-clear-after {
  &:after {
    content: '' !important;
  }
}

.button__search {
  background-color: #97b911;
  float: right;
  width: 47px;
  height: 47px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @include default-icons-absolute-before("\e033", $size: 14px, $color: #fff, $left: 50%, $top: 50%);
  &:before {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: darken(#97b911, 10%);
  }
}

.button2 {
  border: none;
  display: inline-block;
  font-size: 1.3em;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 20px 13px 55px;
  width: 100%;
  &:before {
    font-weight: normal;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &.button-green {
    background-color: #97b911;
    @include default-icons-absolute-before("\e059", $size: 20px, $color: #fff, $left: 26px, $top: 50%);
  }
  &.button-blue {
    background-color: #5090c8;
    padding: 13px 15px 12px 42px;
    @include default-icons-absolute-before("\e081", $size: 13px, $color: #fff, $left: 19px, $top: 50%);
  }
  &.button-purple {
    background-color: #572381;
    padding: 11px 10px 13px 40px;
    @include default-icons-absolute-before("\e000", $size: 13px, $color: #fff, $left: 15px, $top: 50%);
  }
}

.button-toutes {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #572381;
  padding-left: 25px;
  margin-top: 22px;
  @include default-icons-absolute-before("\e000", $size: 13px, $color: #572381, $left: 0, $top: 3px);
}

.btn-location {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  background: #5090c8;
  padding: 22px 40px 21px 62px;
  text-align: left;
  position: relative;
  &:hover,
  &:focus {
    background: darken(#5090c8, 10%);
  }
  &:after,
  &:before {
    position: absolute;
    top: 50%;
    left: 23px;
    font-family: icons-default;
    font-size: 32px;
    color: #fff;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-weight: 400;
    content: '';
  }
  &:before {
    content: '\e04d';
  }
  &:after {
    left: auto;
    right: 8px;
    content: '\e013';
  }
}

.download-link,
.archive-link {
  position: relative;
  padding: 0 0 0 23px;
  color: #000;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.2;
  text-transform: uppercase;
  span {
    font-weight: 300;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 17px;
    line-height: 1;
    font-family: icons-default;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    content: '';
  }
}

.download-link {
  &:after {
    content: '\e064';
  }
}

.archive-link {
  &:after {
    content: '\e06e';
  }
}

.btn-valide {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 700;
  background: #5090c8;
  border: none;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding: 15px 10px 15px;
  font-family: 'roboto condensed';
  &:before {
    font-family: icons-default;
    display: inline-block;
    vertical-align: -1px;
    font-size: 15px;
    line-height: 1;
    color: #fff;
    font-weight: 400;
    margin: 0 5px 0 0;
    content: '\e057';
  }
  &:focus,
  &:hover {
    background: darken(#5090c8, 10%);
  }
}

.btn-play {
  background: #97b911;
  width: 53px;
  height: 53px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-left: 10px solid #fff;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    content: '';
  }
}

.btn-infinity {
  font-size: 1.2rem;
  line-height: 1.2;
  display: block;
  margin: 0 auto;
  padding: 0 0 0 18px;
  text-transform: uppercase;
  font-weight: 300;
  position: relative;
  font-family: 'roboto condensed';
  &:after {
    position: absolute;
    top: 48%;
    left: 0;
    color: #572381;
    font-size: 12px;
    line-height: 1;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-family: icons-default;
    content: '\e05f';
  }
}

.btn-phone {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.2;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0 2px 15px;
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    left: -2px;
    font-size: 13px;
    line-height: 1;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-family: icons-default;
    font-weight: 400;
    content: '\e02b';
  }
}

.btn-email {
  background: #97b911;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 700;
  padding: 13px 29px 13px 52px;
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    left: 28px;
    width: 15px;
    height: 15px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background: url(../Images/pictos/ico-web.svg) no-repeat;
    background-size: 15px 15px;
    content: '';
  }
}

.btn-add {
  font-size: 1.3rem;
  line-height: 1.2;
  color: #fff;
  background: #572381;
  display: inline-block;
  vertical-align: top;
  padding: 15px 25px 14px 41px;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'roboto condensed';
  &:after {
    font-family: icons-default;
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 11px;
    line-height: 1;
    font-weight: 400;
    content: '\e000';
  }
  &:focus,
  &:hover {
    background: darken(#572381, 10%);
  }
}

.button-twocolumns {
  margin: 0 -1px;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  &-col {
    width: 50%;
    float: left;
    padding: 0 1px;
  }
  a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    &.button-2,
    &.button-1 {
      font-size: 1.4rem;
      line-height: 1.2;
      padding: 11px 5px 10px 27px;
    }
  }
}

.tools .ddm__button {
  background: transparent;
  border: 1px solid $color-1--2;
  overflow: hidden;
  &:before {
    color: $color-1--2;
  }
}
