.filter-content {
  overflow: hidden;
  position: relative;
  margin-top: -40px;
  margin-bottom: 20px;
  form,
  fieldset {
    margin: 0;
  }
  .tabset-frame {
    overflow: hidden;
  }
  .tabset {
    float: right;
    margin: 0;
    li {
      float: left;
      padding: 0;
      margin-left: 10px;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
    a {
      background: #ececec;
      display: block;
      width: 165px;
      height: 40px;
      font-size: 16px;
      font-weight: 300;
      color: #000;
      text-align: center;
      text-decoration: none;
      padding-top: 10px;
      &:hover,
      &.active {
        background: darken(#ececec,10%);
      }
      &:focus {
        outline-offset: -1px;
      }
    }
  }
  .tab-list {
    background-color: #f9f9f9;
    padding: 15px 20px 0;
    min-height: 110px;
  }
  legend {
    margin-bottom: 11px;
  }
  .filters__button-wrapper {
    padding: 0;
  }
  .filters__fields.filters__fields--column-3 {
    .filters__fields-wrapper {
      > .filters__field-wrapper {
        flex: 0 0 46%;
        & + .filters__field-wrapper {
          margin-left: 11px;
        }
      }
    }
  }
  .js-tab-hidden {
    form {
      display: none;
    }
  }
}