.list-type-1--participatory {
	.list__item {
		background-color: #f4f4f4;
		padding: 2.2rem;
		margin-bottom: 4rem;

		.list-type-1__picture {
			padding: 0.8rem;
		}

		.category {
			color: $color-1--2;
			font-style: normal;
		}

		.comment,
		.vote {
			color: $color-white;
			font-size: 1.3em;
			margin: 0 0 0.5rem 1rem;
			padding: 1.2rem 2rem;
			text-transform: uppercase;

			@include default-icons-before('', 0, 1em, null, -0.1em, 'icons-project');

			&::before {
				margin-right: 0.25rem;
			}
		}

		.comment {
			background-color: $color-1--2;

			&::before {
				content: '\e00c';
			}
		}

		.vote {
			background-color: $color-2--2;

			&::before {
				content: '\e00f';
			}
		}

		.date {
			font-style: italic;
		}

		.votes {
			color: #595959;
			background-color: #e8e8e8;
			font-weight: $bold;
			font-size: 1.2em;
			display: flex;
			margin: 0;
			padding: 0.25rem 1rem;
			position: absolute;
			right: 0;
			bottom: 0;

			li {
				padding: 1rem;
				display: block;
				text-align: center;

				@include default-icons-before('', 0, 1em, null, -0.1em, 'icons-project');

				&::before {
					margin-right: 0.3rem;
				}
			}

			.votes__yeah {
				&::before {
					content: '\e00e';
					color: #97b911;
					margin-bottom: -0.2rem;
				}
			}

			.votes__comments {
				&::before {
					color: #5090c8;
					content: '\e00a';
				}
			}
		}
	}
}
