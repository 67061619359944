// @name Votes
.votes {
  .radio {
    > div {
      margin-right: 2rem;
    }
    .button-2 {
      margin-top: -1.5rem;
    }
  }
}

.votes__title {
  font-size: 30px;
  font-weight: normal;
  text-transform: uppercase;
  color: #201d1d;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 41px;
  &:after {
    background-color: #5090c8;
    display: block;
    content: '';
    width: 36px;
    height: 6px;
    position: absolute;
    left: 0;
    top: 100%;
  }
}

.votes__results {
  font-size: 1.4rem;
  margin: 1.5em 0 2.5em;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 3rem;
  }
}
