// @name List infos
.list-infos {
  font-family: $typo-2;
  a {
    color: $color-black;
    text-decoration: underline;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  p {
    font-size: 1.4rem;
  }
}
.list-infos__next {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid black;
}
.list-infos__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  > * {
    flex: 1 1 0;
  }
  &.-divide {
    padding-top: 18px;
    border-top: 1px solid currentColor;
  }
  .list-infos__listitems:first-child {
    flex: 1.9 1 0;
  }
  .list-infos__listitems + .list-infos__listitems {
    border-left: 1px solid $color-3--3;
    padding-left: 5%;
    margin-left: 5%;
  }
  .list-infos__listitems--equals {
    padding: 0 2% 0 0;
    margin: 0;
    &:first-child {
      flex: 1 1 0;
    }
    & + .list-infos__listitems--equals {
      padding: 0 0 0 2%;
      margin: 0;
    }
  }
  .list-infos__listitems--no-border,
  .list-infos__listitems--equals + .list-infos__listitems--no-border,
  .list-infos__listitems + .list-infos__listitems--no-border {
    border: none;
  }
}
.list-infos__item {
  $list-infos__item__font-size: 1.4;
  margin: .5em 0;
  font-size: 1.4rem;
  a {
    color: $color-black;
    text-decoration: underline;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  &.list-infos__address {
    padding-left: 20px;
    @include default-icons-absolute-before('\e053', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__website {
    padding-left: 20px;
    @include default-icons-absolute-before('\e089', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__phone {
    padding-left: 20px;
    @include default-icons-absolute-before('\e02b', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__smart-phone {
    padding-left: 20px;
    @include default-icons-absolute-before('\e087', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__fax {
    padding-left: 20px;
    @include default-icons-absolute-before('\e088', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__email {
    padding-left: 20px;
    @include default-icons-absolute-before('\e029', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__email2 {
    padding-left: 20px;
    @include default-icons-absolute-before('\e02a', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__infos {
    padding-left: 20px;
    @include default-icons-absolute-before('\e083', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__hours {
    padding-left: 20px;
    @include default-icons-absolute-before('\e05b', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__navigate {
    padding-left: 20px;
    @include default-icons-absolute-before('\e095', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
  &.list-infos__message {
    padding-left: 20px;
    @include default-icons-absolute-before('\e02e', em(1.4, $list-infos__item__font-size), #5090c8, em(0.2, 1.4), inherit, inherit, 0);
  }
}

.list-infos2 {
  margin-bottom: 22px;
  .list-infos__item {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.6em;
    &:before {
      color: #5090c8;
    }
  }
}
