.info-block {
	&-content {
		padding: 20px;
		display: block;
	}
	&-address,
	&-contact,
	&-description {
		width: 100%;
		overflow: hidden;
	}
	&-address,
	&-contact {
		margin: 0 0 20px;
	}
}