.events-list,
.content .events-list  {
	display: flex;
	margin: 0 -20px;
	justify-content: space-between;
	text-align: center;
	li {
		padding: 0 20px;
		width: 25%;
		max-width: 292px;
	}
	&-img {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&-date {
		margin: -37px 0 28px;
	}
	&-category {
		display: block;
		color: #fff;
		font-size: 1.4rem;
		line-height: 1.2;
		font-style: italic;
		text-transform: uppercase;
		font-weight: 700;
		margin: 0 0 7px;
	}
	&-title {
		font-size: 2rem;
		line-height: 1.2;
		font-weight: 400;
		margin: 0 0 21px;
		color: #fff;
		a {
			color: #fff;
		}
	}
	&-date-inline {
		color: #caddee;
		font-weight: 300;
		padding: 13px 0 0;
		font-size: 1.3rem;
		line-height: 1.25;
		position: relative;
		&:after {
			width: 72px;
			height: 1px;
			position:absolute;
			top:0;
			left:50%;
			background: #caddee;
			-webkit-transform: translate(-50%, 0);
			-ms-transform: translate(-50%, 0);
			transform: translate(-50%, 0);
			content: '';
		}
	}
	&_single {
		li {
			a {
				text-decoration: none;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		.events-list-category {
			color: #000;
		}
		.events-list-title {
			color: #000;
			a {
				color: #000;
				&:hover,
				&:focus {
					color: #000;
				}
			}
		}
		.events-list-date-inline {
			color: #000;
		}
		.events-list-date {
			margin: 0 0 7px;
			.date-1 {
				display: inline-block;
				vertical-align: top;
			}
			&-inline {
				&:after {
					background: #000;
				}
			}
		}
	}
}

.content .events-list {
	li {
		&:before {
			display: none;
		}
	}
}