.media-twocolumns,
.content .media-twocolumns {
	overflow: hidden;
	padding: 0 0 14px;
	.classical-gallery-small {
		margin: 0;
		float: left;
		width: 57.4%;
	}
	.maps-wrap {
		width: 40.8%;
		float: right;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}