// @name Videos
.content {
  .videos {
    margin: 4em 0 5em;
  }
  .video__title {
    $video__title__font-size: 1.6;
      font-family: $typo-2;
      text-align: right;
      font-weight: $normal;
      font-size: #{$video__title__font-size}em;
      color: $color-3--3;
      margin-bottom: em(1.5, $video__title__font-size);
  }
  .video__title2 {
    font-size: 2.4em;
    text-align: center;
    color: #000;
    padding-top: 16px;
    margin-bottom: 32px;
    .link-bloc {
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
    }
    .number {
      font-size: 48px;
      color: #572381;
      position: relative;
      &:after {
        background-color: #97b911;
        display: block;
        content: '';
        width: 100%;
        height: 5px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    em {
      font-size: 16px;
      font-weight: 300;
      color: #5090c8;
      display: block;
      padding-top: 19px;
    }
  }
  .videos__item {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    video {
      width: 100%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .videos__ratio {
    display: block;
    width: 100%;
    height: auto;
  }
  .ce-textpic {
    .videos,
    .videos__item {
      margin: 0;
    }
  }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
  #content {
    .content,
    .setPos {
      &:only-of-type {
        .videos {
          margin: 0;
          .videos__item {
            &:only-of-type {
              margin: 0;
            }
          }
        }
      }
    }
  }
}