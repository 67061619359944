// Newsletter inscription
.newsletter {
  background: #fff;
  width: 220px;
  padding: 18px 20px 24px;
  &__title {
    color: #000;
    font-size: 2.5rem;
    line-height: 1;
    text-transform: uppercase;
    font-style: italic;
    margin: 0 0 21px;
    font-weight: 700;
  }
  label {
    color: #282425;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    height: 29px;
    padding: 0;
    color: #aaa9a9;
    font-weight: 300;
    font-style: italic;
    font-size: 12px;
  }
  &__fields-wrapper {
    margin: 0 0 28px;
  }
  .btn-valide {
    display: block;
    width: 100%;
  }
  &__links {
    display: flex;
    justify-content: space-between;
    li {
      display:inline-block;
      vertical-align:top;
    }
    a {
      color: #282425;
      font-size: 1.1rem;
      line-height: 1.2;
      text-transform: uppercase;
      padding: 0 0 0 10px;
      position: relative;
      font-weight: 700;
      &:after {
        font-family: icons-default;
        font-size: 12px;
        line-height: 1;
        position:absolute;
        top:50%;
        left:0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        content: '\e011';
      }
    }
  }
  form {
    margin: 0 0 9px;
  }
}