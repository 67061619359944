// @name Wrapper with wide 940px
.wrapper-940 {
	max-width: 96em;
	margin: 0 auto;
	padding: 0 1em;
	@extend .clear-fix;
}

.wrapper-1300 {
  max-width: 1320px;
  padding: 0 10px;
  margin: 0 auto;
  @extend .clear-fix;
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
  position: relative;
  background: $color-3--1;
  &:before, &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    width: 999em;
    height: 100%;
    background: $color-3--1;
  }
  &:before {
    left: -9990px;
  }
  &:after {
    right: -9990px;
  }
  // @description Displayed in dark background on the sides.
  &.background-in-wrapper--dark {
    background: $color-3--3;
    &:before, &:after {
      background: $color-3--3;
    }
  }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 24;
$column-bloc__padding-left: 6;
.column-bloc {
	width: #{$column-bloc__width}em;
	padding-left: #{$column-bloc__padding-left}em;
  margin-bottom: 6.6em;
  &:last-child {
    margin-bottom: 0;
  }
  &.bloc-publications {
    margin-bottom: 7em;
  }
  &.block-post {
    margin-bottom: 5em;
  }
}
.column-bloc__wrapper {
  padding: 0;
}