// @name Main section heading
// @description Default css

h1 {
  font-family: $typo-3;
  font-weight: $bold;
  font-size: 4.5rem;
  line-height: 1.1;
  color: $color-black;
  margin-bottom: 20px;
  font-weight: 400;
}

$heading__h1__font-size: 4.5;
.heading {
  background: #f4f4f4;
  padding: 6em 0 4em;
  position: relative;
  &-gray {
    background: $color-3--1;
  }
  &-map-buttons {
    padding: 30px 0 15px 0;
    a + a {
      margin: 0 0 0 8px;
    }
  }
  &-buttons {
    padding-top: 5px;
    float: right;
    .button-1--rss {
      background: #fff;
      &:hover,
      &:focus {
        text-decoration: none;
        background: $color-1--2;
        color: color-contrast($color-1--2);
        &:before {
          color: color-contrast($color-1--2);
        }
        svg {
          fill: color-contrast($color-1--2);
        }
      }
    }
    a {
      float: left;
      margin: 0 0 0 10px;
    }
  }
  .heading__wrapper {
    @extend .wrapper-940;
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .heading__figure {
    margin-right: 2em;
    display: table;
  }
  // right image
  .heading__wrap {
    + .heading__wrapper-figure {
      .heading__figure {
        margin-right: 0;
        margin-left: 2em;
      }
    }
  }
  .heading__picture {
    display: block;
    margin-bottom: .5em;
    img {
      max-width: 280px;
      height: auto;
    }
  }
  .heading__caption {
    display: table-caption;
    caption-side: bottom;
  }
  .heading__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .comment,
  .vote {
    color: $color-white;
    font-size: 1.3em;
    margin: 0 1rem 0.5rem 0;
    padding: 1.2rem 2rem;
    text-transform: uppercase;

    @include default-icons-before('', 0, 1em, null, -0.1em, 'icons-project');

    &::before {
      margin-right: 0.25rem;
    }
  }

  .comment {
    background-color: $color-1--2;

    &::before {
      content: '\e00c';
    }
  }

  .vote {
    background-color: $color-2--2;

    &::before {
      content: '\e00f';
    }
  }

  h1 {
    font-family: $typo-3;
    font-weight: $bold;
    font-size: #{$heading__h1__font-size}em;
    line-height: 1.1;
    color: $color-black;
    margin-bottom: 5px;
    overflow: hidden;
  }
  h1 + .teaser-2 {
    margin-top: em(2.4, $teaser-2__font-size);
  }
  .subtitle {
    $category__font-size: 1.8;
    font-size: #{$category__font-size}em;
  }
  .subtitle + h1 {
    margin-top: em(1.2, $heading__h1__font-size);
  }
  .date {
    font-size: 1.4em;
  }
  .status {
    $status__font-size: 1.5;
    background: #fff;
    font-size: #{$status__font-size}em;
    margin-top: em(0.5, $status__font-size);
    display: inline-block;
    &.status--new {
      background: $color-1--2;
      color: $color-white;
      &:before {
        content: "\e093";
        color: $color-white;
      }
    }
    &.status--in-progress {
      background: $color-2--2;
      color: $color-white;
      &:before {
        content: "\e093";
        color: $color-white;
      }
    }
  }
  .heading-text {
    float: left;
    width: 65%;
  }
}
.section-main {
  .heading {
    padding-top: 2.5em;
  }
}
.heading-holder {
  overflow: hidden;
  figure {
    float: right;
    margin-left: 20px;
    margin-top: 20px;
    width: 32%;
    text-align: right;
  }
}
.figure-heading {
  img {
    display: inline-block;
    margin-bottom: 10px;
  }
  .caption {
    float: right;
    padding-right: 15px;
    position: relative;
    font-size: 12px;
    font-style: italic;
    color: #000;
    position: relative;
    @include default-icons-absolute-before("\e00f", $size: 12px, $color: #774e99, $right: 0, $top: 0);
    &:before {
      font-style: normal;
    }
  }
}

// @name Heading single
// @dependency .heading
.heading--single {
  .heading__title {
    flex-shrink: 0;
    margin-bottom: 3rem;
    width: 100%;
  }
  .heading__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .heading__wrapper-2 {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .heading__media {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid darken($color-3--1, 20%);
    .date, .media {
      font-size: 1.6em;
    }
    .media {
      font-weight: $bold;
      border: none;
      & + .date {
        &:before {
          content: " - ";
          display: inline;
          font-size: 1.2em;
          color: $color-3--3;
        }
      }
    }
  }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
  .heading__wrapper {
    display: block;
    flex-wrap: inherit;
  }
  .heading-bottom {
    margin-top: 20px;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    .all-dates__item {
      float: right;
      width: auto;
      .button-1 {
        background: #ececec;
        &:hover,
        &:focus {
          background: #572381;
        }
      }
      .ddm--active {
        .button-1 {
          background: #572381 !important;
        }
      }
    }
  }
  .heading__figure {
    margin-right: 0;
  }
  .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
    display: table-cell;
    vertical-align: top;
  }
  .heading__wrapper-date{
    .hour-place{
      .place{
        a{
          color:#000;
        }
      }
    }
  }
  .heading__wrapper-date {
    width: 19em;
    border-right: 1px solid $color-3--3;
  }
  .heading__wrap {
    padding-left: 2em;
  }
}

// @name Heading single vins
// @dependency .heading
.heading--single-vins {
  .heading__picture {
    img {
      width: 150px;
      max-width: inherit;
      height: auto;
    }
  }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
  .heading__picture {
    img {
      width: 220px;
      max-width: inherit;
      height: auto;
    }
  }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
  .list-infos {
    margin-top: 2em;
  }
  .list-infos__wrapper {
    .list-infos__listitems:first-child {
      flex: 1 1 0;
    }
  }
  .list-infos__item {
    padding-left: em(2, 1.6);
    font-size: 1.6em;
  }
}

.decor-title {
  font-size: 3.8rem;
  line-height: 1.2;
  font-weight: 400;
  position: relative;
  padding: 0 0 12px;
  text-transform: uppercase;
  &:after {
    width: 45px;
    height: 7px;
    position:absolute;
    bottom: 0;
    left:0;
    background: #572381;
    content: '';
  }
  &-blue {
    &:after {
      background: #5090c8;
    }
  }
  &-yellow {
    &:after {
      background: #ffdc00;
    }
  }
  &-green {
    &:after {
      background: #97b911;
    }
  }
  &-wh-color {
    color: #fff;
  }
}
