.list-type-5,
.content .list-type-5 {
  margin: 0;
  li {
    padding: 0;
    min-height: 80px;
    margin: 0;
    &:not(:first-child) {
      margin-top: 26px;
    }
    &:before {
      display: none;
    }
  }
  .subtitle2 {
    margin: 0 0 5px;
  }
  &-title {
    font-size: 2rem;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    a {
      color: #000;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  .date-1 {
    float: left;
    margin: 0 20px 0 0;
  }
}