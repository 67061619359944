// Galerie classique
.content {
  max-width: 940px;
  .classical-gallery__title {
    $classical-gallery__title_font-size: 1.6;
    font-family: $typo-2;
    text-align: right;
    font-weight: $normal;
    font-size: #{$classical-gallery__title_font-size}em;
    color: $color-3--3;
    margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
  }
  .classical-gallery {
    margin: 1em 0 5em;
    &__nb-items {
      display: none;
    }
    &:after {
      display: block;
      content: '';
      clear: both;
    }
  }
  .slider-galerie {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 10px;
    .slider-galerie__item {
      width: 100%;
      height: 100%;
      z-index: 1 !important;
      border: none;
      background: $color-3--1;
    }
    .infos-img {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      width: calc(100% - 500px);
      display: table;
      padding: 7px 10px;
      padding-left: 0;
      font-family: $typo-2;
      font-weight: $light;
      font-style: italic;
      color: #727272;
      font-size: 1.2em;
      text-align: left;
      white-space: normal;
      &__nbItems {
        display: table-cell;
        vertical-align: bottom;
        white-space: nowrap;
        width: 70px;
      }
      &__wrap {
        display: table-cell;
        vertical-align: top;
      }
      &__legend {
        display: block;
        font-style: normal;
        @include default-icons-before("\e00f", 0 5px -8px -3px, 16px, #6d4092, middle);
        &:before {
          font-style: normal;
          position: relative;
          top: -5px;
        }
      }
    }
    a {
      display: block;
      width: 100%;
      height: auto;
      text-align: center;
      position: relative;
      white-space: nowrap;
      padding: 68.08510% 0 0 0; // 100/(940/640)
      figure {
        display: inline;
      }
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 50%;
      right: 0;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .sliderVideo {
    margin-bottom: 0;
    .infos-img {
      position: inherit;
      bottom: inherit;
      top: inherit;
      left: inherit;
      right: inherit;
    }
  }
  .carousel-galerie {
    width: 500px;
    float: right;
    display: inline-block;
    text-align: right;
    position: relative;
    z-index: 1;
    .item {
      border: none;
    }
  }
  .carousel-galerie__thumb {
    width: 340px;
    height: 74px;
    z-index: 2;
    margin-left: 80px;
    text-align: left;
    &.no-carousel {
      position: relative;
      .carousel-galerie__item {
        display: inline-block;
        margin: 0 3px;
      }
    }
  }
  .carousel-galerie__item {
    z-index: 1 !important;
    text-align: center;
    &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
      &:before {
       background: rgba(0, 0, 0, 0);
      }
    }
    button {
      position: relative;
      &:before {
        transition: all ease .2s;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
      }
      img {
        width: 74px;
        height: 74px;
        &.carousel-galerie__item-image-width{
          width:auto;
          padding:0 10px;
        }
      }
    }
    &:hover {
      button {
        &:before {
          background: rgba(124, 152, 14, 0.5) !important;
        }
      }
    }
  }
  .cycle-slide-active {
    button {
      &:before {
        background: rgba(124, 152, 14, 0.5) !important;
      }
    }
  }
  .carousel-galerie__pager {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 74px;
    width: 500px;
    z-index: 1;
  }
  .carousel-galerie__prev, .carousel-galerie__next {
    position: absolute;
    top: 0;
    padding: 0;
    button {
      width: 74px;
      height: 74px;
      background: $color-2--2;
      text-indent: -9999px;
      transition: all ease .2s;
      //outline: none;
      @include default-icons-absolute-before('\e026', em(2, 1.3), $color-white, 50%, inherit, inherit, 50%);
      &:before {
        text-indent: 0;
        transform: translateY(-50%) translateX(-50%);
      }
      &:hover, &:focus {
        background: $color-2--3;
      }
    }
    &:before {
      content: "";
      speak: none;
    }
  }
  .carousel-galerie__prev {
    left: 0;
  }
  .carousel-galerie__next {
    right: 0;
    button {
      &:before {
        content: "\e027";
      }
    }
  }
  .classical-gallery-small {
     .classical-gallery {
        margin: 0 0 30px;
      }
    @media (min-width: 961px) {
      .slider-galerie {
        margin-bottom: 6px;
      }
      .classical-gallery {
        margin: 0;
      }
      .carousel-galerie__prev button,
      .carousel-galerie__next button {
        width: 56px;
        height: 56px;
      }
      .carousel-galerie__item button img {
        width: 56px;
        height: 56px;
      }
      .carousel-galerie {
        width: 382px;
      }
      .carousel-galerie__pager {
        width: 100%;
      }
      .carousel-galerie__thumb {
        width: 258px;
        margin-left: 61px;
      }
      .slider-galerie .infos-img {
        width: calc(100% - 370px);
      }
    }
  }
}

