.list-document__listitems {
	margin: 0;
}

.list-document__item {
	display: block;
	width: 100%;
	padding: 0 0 40px;
	&-one {
		max-width: none;
		float: none;
	}
	&--postulate {
		float: none;
		width: 100%;
		.button-2 {
			display: block;
			width: 100%;
			font-size: 2rem;
			text-align: center;
			padding: 20px 10px;
			&:before {
				font-size: 16px;
			}
		}
	}
}