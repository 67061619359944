// @name Pager list
.pager,
.content .pager {
  li {
    &.pager__prev, &.pager__next {
      span, a {
        width: 40px;
        height: 40px;
        padding: 0;
        position: relative;
        font-size: 0;
        line-height: 0;
        &:after,
        &:before {
          position:absolute;
          top:50%;
          left:50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          margin: 0;
        }
      }
    }
  }
}