.site-infos {
	float: none;
	width: auto;
	margin: 0 -10px;
	padding: 0 10px 24px;
	border-bottom: 1px solid #8796c5;
	&-contact {
		text-align: center;
		.btn-phone {
			text-align: left;
			float: none;
			display: block;
			margin: 0 0 22px;
		}
		.btn-email {
			float: none;
			display:inline-block;
			vertical-align:top;
		}
	}
}

.footer-logo {
	margin: 0 0 24px;
}