.block-post {
  h3 {
    margin-bottom: 1.1em;
  }
  .postItem {
    border-bottom: 1px solid #afafaf;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 300;
    padding-bottom: 11px;
    margin-bottom: 15px;
    a {
      font-weight: bold;
      color: #5090c8;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}