.mobile-menu {
	position:absolute;
	top:0; 
	left:0;
	-webkit-transform: translate(100%, 0);
	-ms-transform: translate(100%, 0);
	transform: translate(100%, 0);
	-webkit-transition:-webkit-transform 0.3s linear;
	transition:transform 0.3s linear; 
	background: #fff;
	color: #fff;
	z-index: 999;
	padding: 0 20px;
	width: 100%;
	&-opener {
		float: right;
		width: 60px;
		height: 60px;
		position: relative;
		margin: 15px 0 0 10px;
		display: block;
		background: #6072ac;
		text-indent: -9999px;
		overflow: hidden;
		span {
			width: 24px;
			height: 5px;
			background: #fff;
			position:absolute;
			top:50%;
			left:50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			&:after,
			&:before {
				width: 24px;
				height: 5px;
				background: #fff;
				top: -8px;
				position:absolute;
				left:50%;
				-webkit-transform: translate(-50%, 0);
				-ms-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
				content: '';
			}
			&:after {
				top: 8px;
			}
		}
	}
	&-overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 998;
		background: rgba(0,0,0,0.7);
		display: none;
	}
	&-top {
		padding: 19px 20px 17px;
		margin: 0 -20px;
		overflow: hidden;
		background: url(../Images/bg/bg-decor-02.png) no-repeat 50% 0;
	}
	.btn-menu {
		float: left;
		color: #6072ac;
		font-size: 1.9rem;
		line-height: 1.2;
		font-weight: 700;
		background: none;
		border: none;
		padding: 0 0 0 26px;
		text-transform: uppercase;
		position: relative;
		height: 40px;
		font-family: 'roboto condensed';
		span {
			width: 16px;
			height: 4px;
			background: #6072ac;
			position:absolute;
			top:50%;
			left:0;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
			&:after,
			&:before {
				width: 16px;
				height: 4px;
				background: #6072ac;
				top: -6px;
				position:absolute;
				left:0;
				content: '';
			}
			&:after {
				top: 6px;
			}
		}
	}
	.btn-close {
		float: right;
		width: 40px;
		height: 40px;
		position: relative;
		background: none;
		border: none;
		text-indent:-9999px;
		overflow:hidden;
		margin: 0 -12px 0 0;
		&:after,
		&:before {
			position:absolute;
			top:50%;
			left:50%;
			-webkit-transform: translate(-50%, -50%) rotate(-45deg);
			-ms-transform: translate(-50%, -50%) rotate(-45deg);
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 2px;
			height: 21px;
			background: #6072ac;
			content: '';
		}
		&:before {
			-webkit-transform: translate(-50%, -50%) rotate(45deg);
			-ms-transform: translate(-50%, -50%) rotate(45deg);
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
	.side-menu {
		display: block;
	}
	.menu-main__drop-close {
		display: none;
	}
}
.main-mob-menu {
	border-top: 1px solid #97b911;
	margin: 0 -20px;
	.menu-main__level-1 {
		float: none;
		> li {
			float: none;
			border-bottom: 1px solid #97b911;
			&.active {
				> .title {
					background: #572381;
					a {
						color: #fff;
					}
					.toggle {
						border-left-color: #fff;
						&:after {
							background: #fff;
						}
						&:before {
							display: none;
						}
					}
				}
			}
			a.active {
				background: none;
			}
			> .title {
				padding: 0 52px 0 0;
				position: relative;
				a {
					padding: 32px 20px 32px 39px;
					display: block;
					color: #000;
					font-size: 1.7rem;
					line-height: 1.2;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
					position: relative;
					&:after {
						border-top: 6px solid #6072ac;
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
						position:absolute;
						top:50%;
						left:20px;
						-webkit-transform: translate(0, -50%);
						-ms-transform: translate(0, -50%);
						transform: translate(0, -50%);
						display: block;
						content: '';
					}
				}
				.toggle {
					width: 52px;
					height: 38px;
					position:absolute;
					top:50%;
					right: 0;
					-webkit-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					transform: translate(0, -50%);
					border-left: 1px solid #697cb7;
					text-indent:-9999px;
					overflow:hidden;
					outline: 2px dotted #000;
					outline-offset: -.2rem;
					&:before,
					&:after {
						width: 1px;
						height: 11px;
						position:absolute;
						top:50%;
						left:50%;
						-webkit-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						background: #697cb7;
						margin: 0 0 0 -1px;
						content: '';
					}
					&:after {
						width: 11px;
						height: 1px;
					}
				}
			}
		}
	}
	.menu-main__drop {
		background: #572381;
		padding: 15px 38px 12px;
		position: static;
		&-col {
			float: none;
			width: 100%;
			padding: 0;
			&.active {
				.toggle {
					background: #fff;
					&:before {
						display: none;
					}
					&:after {
						background: #3c3c3c;
					}
				}
			}
			.title {
				padding: 0 27px 0 0;
				border-bottom: 3px solid #2385a5;
				position: relative;
			}
			.toggle {
				width: 28px;
				height: 28px;
				position:absolute;
				top:50%;
				right: 0;
				-webkit-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				transform: translate(0, -50%);
				border: 1px solid #fff;
				text-indent:-9999px;
				overflow:hidden;
				&:before,
				&:after {
					width: 1px;
					height: 11px;
					position:absolute;
					top:50%;
					left:50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					background: #fff;
					content: '';
				}
				&:after {
					width: 11px;
					height: 1px;
				}
			}
		}
		&-title {
			border: none;
			margin: 0 0 14px;
			padding: 0;
			cursor: pointer;
			font-size: 1.6rem;
			a {
				padding: 0 0 9px;
				&:after {
					width: 32px;
					-webkit-transform: translate(0, 0) !important;
					-ms-transform: translate(0, 0) !important;
					transform: translate(0, 0) !important;
				}
			}
		}
		&-col {
			margin: 0 0 18px;
		}
	}
	.menu-main__drop-list {
		padding: 0;
		margin: 0;
		li {
			margin: 0 0 4px;
		}
		a {
			padding: 4px 10px 4px 18px;
			&:after {
				left: 4px;
			}
		}
	}
}

.mob-menu-opened {
	.mobile-menu {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		&-overlay {
			display: block;
		}
	}
}