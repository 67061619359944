// @name Teaser 2
// @description Teaser in heading
.teaser-2 {
  font-size: 1.6em;
  line-height: 1.2em;
}

.text-wrap,
.content .text-wrap {
	margin: 0 0 10px;
}