// @name Subtitle or category
$category__font-size: 1.4;
.subtitle, .category {
  font-family: $typo-2;
  font-weight: $bold;
  font-style: italic;
  font-size: #{$category__font-size}em;
  text-transform: uppercase;
  color: #5090c8;
  margin-bottom: 5px;
  a {
    color: #5090c8;
  }
}

.subtitle2 {
  color: #572381;
  font-size: 1.2rem;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 7px;
  display: block;
}

// @name caption
// @description Default caption
.caption {
  font-size: 1.3em;
  font-weight: $light;
  font-style: italic;
  font-family: $typo-2;
  color: $color-3--3;
}

// @name Date
// @description Default date
.date {
  $date__font-size: 1.2;
  font-size: #{$date__font-size}em;
  font-weight: $normal;
  font-family: $typo-2;
  color: $color-3--3;
  margin: em(0.6, $date__font-size) 0;
  &.date--big {

  }
}

// @name Date 1
// @description Date in event
.date-1,
.content .date-1 {
  font-family: $typo-2;
  color: $color-1--3;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 0;
  line-height: 0;
  time {
    background-color: #572381;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding: 16px 10px 17px;
    min-width: 67px;
    color: #fff;
    position: relative;
    & + span + time {
      @include default-icons-absolute-before("\e027", 1.7em, #fff, 50%, inherit, inherit, $left: -5px);
      &:before {
        position:absolute;
        top:50%;
        left: -1px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        border-left: 8px solid #fff;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        content: '';
      }
    }
  }
  span:first-child + time {
    background-color: #fff;
    margin-left: 0;
    color: #572381;
  }
  .day, .month {
    display: block;
    line-height: 1;
  }
  .day {
    font-size: 2.5rem;
    font-weight: $bold;
    margin: 0 0 -1px;
  }
  .month {
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  &-border {
    border: solid #572381;
      border-width: 1px 0 1px 1px;
  }
  &-one-item {
      time {
        background-color: #572381 !important;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        padding: 16px 10px 17px;
        min-width: 67px;
        color: #fff !important;
        position: relative;
      }
  }
}

// @name Date 2
// @description Date in job
$date-2__font-size: 1.4;
$date-2--big__font-size: 2;
.date-2 {
  font-size: #{$date-2__font-size}em;
  text-transform: uppercase;
  color: $color-1--2;
  margin: 0 0 em(.8, $date-2__font-size);
  time {
    font-weight: $bold;
  }
  &.date-2--big {
    font-size: #{$date-2--big__font-size}em;
    margin: em(.5, $date-2--big__font-size) 0 em(.8, $date-2--big__font-size);
  }
}

// @name Date 3
// @description Date in public market
.date-3 {
  $date-3__font-size: 1.2;
  @include default-icons-absolute-before('\e05b', em(1.2, 1.2), $color-1--2, em(1.3, 1.2), inherit, inherit, 0);
  font-family: $typo-2;
  font-size: #{$date-3__font-size}em;
  font-weight: $bold;
  text-transform: uppercase;
  padding-top: em(1.2, $date-3__font-size);
  padding-left: em(1.8, $date-3__font-size);
  margin-bottom: em(1.2, $date-3__font-size);
  border-top: 1px solid darken($color-3--1, 20%);
  time {
    font-weight: $light;
  }
  &.date-3--big {
    $date-3--big__font-size: 1.6;
    font-size: #{$date-3--big__font-size}em;
    padding-top: em(1.2, $date-3--big__font-size);
    padding-left: em(2, $date-3--big__font-size);
    margin-bottom: em(1.2, $date-3--big__font-size);
    margin-top: em(1.2, $date-3--big__font-size);
    &:before {
      font-size: em(1.6, $date-3--big__font-size);
      top: em(1.3, $date-3--big__font-size);
    }
    br {
      display: none;
    }
  }
}

// @name Date 4
// @description Date in publications bloc
.date-4 {
  $date-4__font-size: 1.5;
  font-size: #{$date-4__font-size}em;
  text-transform: uppercase;
  font-weight: $light;
  margin: 0 0 em(2, $date-4__font-size);
  time {
    & + span + time {
      @include default-icons-before('\e017', 0 5px 0 0, em(1.4, $date-4__font-size), $color-black, em(-.1, 1.4));
    }
  }
}

// @name Media
// Media in publications, public market, albums
.media {
  border-top: 1px solid darken($color-3--1, 20%);
  color: $color-3--3;
  text-transform: uppercase;
  font-size: 1.2em;
  padding-top: em(1.2, 1.2);
}

// @name Hour and place wrapper
.hour-place {
  text-align: center;
}

// @name Hour
// @description Default hour
.hour {
  $hour__font-size: 1.8;
  font-family: $typo-2;
  display: block;
  font-weight: $bold;
  font-size: #{$hour__font-size}em;
  margin-bottom: em(0.5, $hour__font-size);
}

// @name Place
// @description Default place
.place {
  display: inline-block;
  font-size: 1.6em;
  font-family: $typo-2;
  font-weight: $light;
  margin-bottom: 12px;
}



// @name Teaser 1
// @description Teaser in list view
.teaser-1 {
  $teaser-1__font-size: 1.3;
  font-size: #{$teaser-1__font-size}em;
  margin: em(.8, $teaser-1__font-size) 0;
}

// @name Teaser 2
// @description Teaser in heading
$teaser-2__font-size: 1.8;
.teaser-2 {
  font-size: #{$teaser-2__font-size}em;
  line-height: em(2.7, $teaser-2__font-size);
  color: #727272;
  margin: em(1.2, $teaser-2__font-size) 0 em(.5, $teaser-2__font-size);
}

// @name Teaser 3
// @description Teaser in bloc of right column
.teaser-3 {
  $teaser-3__font-size: 1.6;
  font-size: #{$teaser-3__font-size}em;
  font-weight: $normal;
  margin: em(.8, $teaser-3__font-size) 0;
}

// @name status
// @description Status for public market
.status {
  $status__font-size: 1.2;
  font-size: #{$status__font-size}em;
  background: $color-3--1;
  padding: em(.5, $status__font-size);
  font-family: $typo-2;
  font-weight: $light;
  text-transform: uppercase;
  margin: 0 0 em(.8, $status__font-size);
  @include default-icons-before('\e057', 0 5px 0 0, em(1, $status__font-size), $color-3--3, 0);
  &.status--new {
    background: $color-1--2;
    color: $color-white;
    &:before {
      content: "\e093";
      color: $color-white;
    }
  }
  &.status--in-progress {
    background: $color-2--2;
    color: $color-white;
    &:before {
      content: "\e093";
      color: $color-white;
    }
  }
}


// @name Message
// @state error - Display error message
$error__font-size: 1.4;
.tx-femanager .message,
.message {
  background: $color-3--1;
  font-size: #{$error__font-size}em;
  font-weight: $light;
  padding: em(3.5, $error__font-size) em(4.2, $error__font-size);
  &:before {
    content: attr(aria-label);
    display: block;
    color: $color-black;
    font-size: em(2, $error__font-size);
    font-weight: $bold;
    margin-bottom: em(2, 2);
  }
  &:after{
    font-family: "icons-default";
    font-size: 2rem;
    color: #ff000c;
    position: absolute;
    top: 35px;
    right: inherit;
    bottom: inherit;
    left: 18px;
    content: "\e07f";
  }
  > li {
    padding-left: em(1.5, $error__font-size);
    @include default-icons-absolute-before('\e027', em(1.2, $error__font-size), $color-black, em(.4, 1.2), inherit, inherit, 0);
    margin: em(.5, $error__font-size) 0;
  }
  &.message--error {
    background: lighten($color-red, 45%);
    position: relative;
    &:before {
      color: $color-red;
    }
  }
  &.message--succes {
     background: lighten($color-green, 65%);
    &:before {
      color: $color-green;
    }
  }
}

.text-wrap,
.content .text-wrap {
  overflow: hidden;
  margin: 0 0 27px;
  p {
    line-height: 1.2;
    margin: 0 0 21px;
  }
}