// @name All dates
.all-dates__title {
  margin-bottom: em(2, $all-dates__title__font-size);
}
.all-dates__listitems {
  display: block;
  justify-content: inherit;
  flex-wrap: inherit;
  padding: 0 2em;
}
.all-dates__item {
  width: 100%;
  &:last-child {
    justify-content: center;
  }
}
