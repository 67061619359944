.mosaic {
	display: flex;
	margin: -10px -10px 0;
	&-col {
		&-lg {
			width: 54.5%;
			display: flex;
			flex-wrap: wrap;
		}
		&-sm {
			width: 45.5%;
			.mosaic-item {
				height: 100%;
			}
		}
	}
	&-box1 {
		height: 100%;
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 62px 7px;
		z-index: 1;
		&:before {
			position:absolute;
			top: 5px;
			bottom: 45px;
			left: -30px;
			right: -30px;
			background: #5090c8;
			-webkit-transform: rotate(-0.8deg);
			-ms-transform: rotate(-0.8deg);
			transform: rotate(-0.8deg);
			z-index: -1;
			content: '';
		}
		&:after {
			position:absolute;
			top: 45px;
			bottom: 5px;
			left: -30px;
			right: -30px;
			background: #5090c8;
			-webkit-transform: rotate(0.8deg);
			-ms-transform: rotate(0.8deg);
			transform: rotate(0.8deg);
			z-index: -1;
			content: '';
		}
		&-title {
			font-size: 3.8rem;
			line-height: 1;
			font-style: italic;
			font-weight: 700;
			color: #fff;
			margin: 0 0 -5px;
			text-transform: uppercase;
			a {
				color: #fff;
			}
		}
		&-subtitle {
			font-size: 2.8rem;
			line-height: 1.2;
			font-style: italic;
			font-weight: 700;
			color: #fff;
			margin: 0 0 28px;
			display: block;
		}
	}
	&-box-project {
		display: flex;
		flex-wrap: wrap;
	}
	&-box2 {
		height: 100%;
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 9px 9px 22px;
		margin: -11px 0 0;
		z-index: 1;
		color: #fff;
		&:before {
			position:absolute;
			top: 5px;
			bottom: 45px;
			left: -30px;
			right: -30px;
			background: #6072ac;
			-webkit-transform: rotate(1.5deg);
			-ms-transform: rotate(1.5deg);
			transform: rotate(1.5deg);
			z-index: -1;
			content: '';
		}
		&:after {
			position:absolute;
			top: 45px;
			bottom: 5px;
			left: -30px;
			right: -30px;
			background: #6072ac;
			-webkit-transform: rotate(-1.5deg);
			-ms-transform: rotate(-1.5deg);
			transform: rotate(-1.5deg);
			z-index: -1;
			content: '';
		}
		.mosaic-title {
			text-align: left;
		}
		.mosaic-img {
			&:after {
				position:absolute;
				top: -5px;
				height: 10px;
				left: -30px;
				right: -30px;
				background: #6072ac;
				-webkit-transform: rotate(1.5deg);
				-ms-transform: rotate(1.5deg);
				transform: rotate(1.5deg);
				content: '';
			}
		}
	}
	&-box3 {
		height: 100%;
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 9px 9px 22px;
		z-index: 1;
		color: #fff;
		&:before {
			position:absolute;
			top: 5px;
			bottom: 45px;
			left: -30px;
			right: -30px;
			background: #97b911;
			-webkit-transform: rotate(-1.5deg);
			-ms-transform: rotate(-1.5deg);
			transform: rotate(-1.5deg);
			z-index: -1;
			content: '';
		}
		&:after {
			position:absolute;
			top: 45px;
			bottom: 18px;
			left: -30px;
			right: -30px;
			background: #97b911;
			-webkit-transform: rotate(1.5deg);
			-ms-transform: rotate(1.5deg);
			transform: rotate(1.5deg);
			z-index: -1;
			content: '';
		}
		.mosaic-img {
			&:after {
				position:absolute;
				top: -5px;
				height: 10px;
				left: -30px;
				right: -30px;
				background: #97b911;
				-webkit-transform: rotate(-1.5deg);
				-ms-transform: rotate(-1.5deg);
				transform: rotate(-1.5deg);
				content: '';
			}
		}
		.mosaic-title {
			font-style: italic;
		}
	}
	&-box4 {
		min-height: 490px;
		height: 100%;
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 20px 9px 22px;
		margin: -11px 0 0;
		z-index: 1;
		color: #1d2f3e;
		&:before {
			position:absolute;
			top: 15px;
			bottom: 45px;
			left: -30px;
			right: -30px;
			background: #ffdc00;
			-webkit-transform: rotate(1deg);
			-ms-transform: rotate(1deg);
			transform: rotate(1deg);
			z-index: -1;
			content: '';
		}
		&:after {
			position:absolute;
			top: 45px;
			bottom: 8px;
			left: -30px;
			right: -30px;
			background: #ffdc00;
			-webkit-transform: rotate(-1deg);
			-ms-transform: rotate(-1deg);
			transform: rotate(-1deg);
			z-index: -1;
			content: '';
		}
		.mosaic-img {
			&:after {
				position:absolute;
				top: -5px;
				height: 10px;
				left: -30px;
				right: -30px;
				background: #ffdc00;
				-webkit-transform: rotate(1deg);
				-ms-transform: rotate(1deg);
				transform: rotate(1deg);
				content: '';
			}
		}
	}
	&-item {
		padding: 0 10px 20px;
		width: 100%;
		&-twocolumns {
			display: flex;
			width: 100%;
			.mosaic-item {
				width: 50%;
				height: 100%;
			}
		}
	}
	&-title {
		font-size: 2.5rem;
		line-height: 1.2;
		color: #fff;
		font-weight: 700;
		display: block;
		&-box {
			overflow: hidden;
			padding-left: 9.5%;
			.mosaic-title {
				color: #1d2f3e;
				font-size: 3.2rem;
				line-height: 1.2;
				overflow: hidden;
				text-align: left;
			}
			&-ico {
				float: left;
				padding: 8px 50px 0 0;
				position: relative;
				&:after {
					position:absolute;
					top:5px;
					right: 17px;
					background: url(../Images/bg/bg-decor-04.png) no-repeat;
					width: 21px;
					height: 72px;
					content: '';
				}
			}
		}
	}
	&-img {
		width: 100%;
		position: relative;
		overflow: hidden;
		&-wrap {
			position: relative;
		}
		&-caption {
			position:absolute;
			bottom: 0;
			left:0;
			width: 100%;
			font-size: 2.1rem;
			line-height: 1.2;
			font-weight: 700;
			text-align: left;
			background: rgba(29,47,62,0.62);
			padding: 23px 19px;
			color: #fff;
		}
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&-content {
		padding: 11px 15px;
		width: 100%;
	}
	&-option-bar {
		border: 1px solid #fff;
		display:inline-block;
		vertical-align:top;
		a {
			color: #fff;
			font-size: 1.6rem;
			line-height: 1.2;
			font-weight: 700;
			text-transform: uppercase;
			display:inline-block;
			vertical-align:top;
			padding: 13px 45px 13px 50px;
			position: relative;

		}
		&-ico {
			position:absolute;
			top:50%;
			left:28px;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
	}
}