.section-1 {
	margin-top: 0;
	&-wrap {
		padding: 0 0 40px;
	}
}

.section-2 {
	margin-top: -30px;
	&-wrap {
		padding: 35px 0 70px;
	}
}

.section-3 {
	margin-top: -35px;
	&-wrap {
		padding: 23px 0 55px;
	}
}

.section-4 {
	margin-top: -28px;
	&-wrap {
		padding: 25px 0 40px;
	}
}

.section-5 {
	margin-top: -29px;
	&-wrap {
		padding: 25px 0 40px;
	}
}

.section-6 {
	margin-top: -27px;
	&-wrap {
		padding: 25px 0 40px;
	}
}

.section-7 {
	margin-top: -28px;
	&-wrap {
		padding: 25px 0 40px;
	}
}