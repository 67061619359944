@charset "UTF-8";

// Desktop view [0 ; ∞]

// 01-utils
@import "01-default/01-utils/_configuration.scss";
@import "01-default/01-utils/_mixins.scss";
@import "01-default/01-utils/_framework.scss";
@import "01-default/01-utils/_reset.scss";
@import "01-default/01-utils/_base.scss";
@import "01-default/01-utils/_grillade.scss";
@import "01-default/01-utils/_styleguide.scss";

// 02-lib
@import "01-default/02-lib/_drop-down-menu.scss";
@import "01-default/02-lib/_access-tooltip.scss";
@import "01-default/02-lib/_tooltip-css.scss";
@import "01-default/02-lib/_fancybox.scss";
@import "01-default/02-lib/_datepicker.scss";
@import "01-default/02-lib/_jquery.timepicker.scss";

// 03-partials
@import "01-default/03-partials/_forms.scss";
@import "01-default/03-partials/_buttons.scss";
@import "01-default/03-partials/_links.scss";
@import "01-default/03-partials/_wrappers.scss";
@import "01-default/03-partials/_layouts.scss";
@import "01-default/03-partials/_text.scss";
@import "01-default/03-partials/_heading.scss";
@import "01-default/03-partials/_titles.scss";
@import "01-default/03-partials/_pagers.scss";
@import "01-default/03-partials/_filters.scss";
@import "01-default/03-partials/_sections.scss";
@import "01-default/03-partials/_cookies-management.scss";
@import "01-default/03-partials/menus/_menu-skip.scss";
@import "01-default/03-partials/menus/_menu-main.scss";
@import "01-default/03-partials/menus/_menu-cross.scss";
@import "01-default/03-partials/menus/_side-menu.scss";
@import "01-default/03-partials/contents/_content.scss";
@import "01-default/03-partials/contents/_content__galeries.scss";
@import "01-default/03-partials/contents/_content__ddm.scss";
@import "01-default/03-partials/contents/_content__subpages.scss";
@import "01-default/03-partials/contents/_content__go-so-far.scss";
@import "01-default/03-partials/contents/_content__parallax.scss";
@import "01-default/03-partials/contents/_content__videos.scss";
@import "01-default/03-partials/contents/_content__typo3.scss";
@import "01-default/03-partials/lists/_lists.scss";
@import "01-default/03-partials/lists/_list-content.scss";
@import "01-default/03-partials/lists/_list-type-1.scss";
@import "01-default/03-partials/lists/_list-type-1--event.scss";
@import "01-default/03-partials/lists/_list-type-1--glossary.scss";
@import "01-default/03-partials/lists/_list-type-1--poll.scss";
@import "01-default/03-partials/lists/_list-type-2.scss";
@import "01-default/03-partials/lists/_list-type-2--publications.scss";
@import "01-default/03-partials/lists/_list-type-3.scss";
@import "01-default/03-partials/lists/_list-type-4.scss";
@import "01-default/03-partials/lists/_list-service.scss";
@import "01-default/03-partials/lists/_list-type-1--news.scss";
@import "01-default/03-partials/lists/_list-type-1--participatory.scss";
@import "01-default/03-partials/lists/_list-type-5.scss";
@import "01-default/03-partials/lists/_list-event-info.scss";
@import "01-default/03-partials/singles/_single.scss";
@import "01-default/03-partials/singles/_single--news.scss";
@import "01-default/03-partials/singles/_single--event.scss";
@import "01-default/03-partials/singles/_single--poll.scss";
@import "01-default/03-partials/objects/_breadcrumb.scss";
@import "01-default/03-partials/objects/_tools.scss";
@import "01-default/03-partials/objects/_cookies.scss";
@import "01-default/03-partials/objects/_logo.scss";
@import "01-default/03-partials/objects/_stratis.scss";
@import "01-default/03-partials/objects/_page-number.scss";
@import "01-default/03-partials/objects/_popin-homepage.scss";
@import "01-default/03-partials/objects/_print-banner.scss";
@import "01-default/03-partials/objects/_newsletter.scss";
@import "01-default/03-partials/objects/_search.scss";
@import "01-default/03-partials/objects/_go-to-top.scss";
@import "01-default/03-partials/objects/_sitemap.scss";
@import "01-default/03-partials/objects/_list-document.scss";
@import "01-default/03-partials/objects/_list-infos.scss";
@import "01-default/03-partials/objects/_comments.scss";
@import "01-default/03-partials/objects/_votes.scss";
@import "01-default/03-partials/objects/_site-infos.scss";
@import "01-default/03-partials/objects/_bloc-news.scss";
@import "01-default/03-partials/objects/_bloc-event.scss";
@import "01-default/03-partials/objects/_bloc-extranet.scss";
@import "01-default/03-partials/objects/_bloc-publications.scss";
@import "01-default/03-partials/objects/_bloc-directory.scss";
@import "01-default/03-partials/objects/_bloc-contact.scss";
@import "01-default/03-partials/objects/_header-block.scss";
@import "01-default/03-partials/objects/_news-block.scss";
@import "01-default/03-partials/objects/_heading-block.scss";
@import "01-default/03-partials/objects/_option-bar.scss";
@import "01-default/03-partials/objects/_news-main.scss";
@import "01-default/03-partials/objects/_news-list.scss";
@import "01-default/03-partials/objects/_mosaic.scss";
@import "01-default/03-partials/objects/_events-block.scss";
@import "01-default/03-partials/objects/_events-list.scss";
@import "01-default/03-partials/objects/_filter-content.scss";
@import "01-default/03-partials/objects/_contact-info.scss";
@import "01-default/03-partials/objects/_map-content.scss";
@import "01-default/03-partials/objects/_block-post.scss";
@import "01-default/03-partials/objects/_block-files.scss";
@import "01-default/03-partials/objects/_location.scss";
@import "01-default/03-partials/objects/_kiosk.scss";
@import "01-default/03-partials/objects/_image-gallery.scss";
@import "01-default/03-partials/objects/_social-block.scss";
@import "01-default/03-partials/objects/_social-list.scss";
@import "01-default/03-partials/objects/_extranet-list.scss";
@import "01-default/03-partials/objects/_extranet-block.scss";
@import "01-default/03-partials/objects/_buttons-row.scss";
@import "01-default/03-partials/objects/_partners.scss";
@import "01-default/03-partials/objects/_fixed-bar.scss";
@import "01-default/03-partials/objects/_social-networks.scss";
@import "01-default/03-partials/objects/_info-block.scss";
@import "01-default/03-partials/objects/_twocolumns-grid.scss";
@import "01-default/03-partials/objects/_collaborator-list.scss";
@import "01-default/03-partials/objects/_equipements-list.scss";
@import "01-default/03-partials/objects/_media-twocolumns.scss";
@import "01-default/03-partials/objects/_waste-management.scss";

// 04-pages

// 05-shame
@import "01-default/05-shame/_shame.scss";


// @name Tablet view [0 ; 960]

@media (max-width: #{$tablet}px) {

  // 01-utils
@import "02-tablet/01-utils/_reset.scss";
@import "02-tablet/01-utils/_base.scss";
@import "02-tablet/01-utils/_styleguide.scss";

// 02-lib
@import "02-tablet/02-lib/_fancybox.scss";

// 03-partials
@import "02-tablet/03-partials/_forms.scss";
@import "02-tablet/03-partials/_wrappers.scss";
@import "02-tablet/03-partials/_heading.scss";
@import "02-tablet/03-partials/_location.scss";
@import "02-tablet/03-partials/_pagers.scss";
@import "02-tablet/03-partials/_layouts.scss";
@import "02-tablet/03-partials/menus/_menu-skip";
@import "02-tablet/03-partials/menus/_menu-main";
@import "02-tablet/03-partials/menus/_mobile-menu";
@import "02-tablet/03-partials/contents/_content.scss";
@import "02-tablet/03-partials/contents/_content__galeries.scss";
@import "02-tablet/03-partials/contents/_content__go-so-far";
@import "02-tablet/03-partials/lists/_list-type-1.scss";
@import "02-tablet/03-partials/lists/_list-type-1--event.scss";
@import "02-tablet/03-partials/lists/_list-type-2.scss";
@import "02-tablet/03-partials/lists/_list-type-3.scss";
@import "02-tablet/03-partials/objects/_search";
@import "02-tablet/03-partials/objects/_cookies.scss";
@import "02-tablet/03-partials/objects/_list-document";
@import "02-tablet/03-partials/objects/_list-infos.scss";
@import "02-tablet/03-partials/objects/_comments.scss";
@import "02-tablet/03-partials/objects/_bloc-publications";
@import "02-tablet/03-partials/objects/_map-content";
@import "02-tablet/03-partials/objects/_filter-content";
@import "02-tablet/03-partials/objects/_news-main.scss";
@import "02-tablet/03-partials/objects/_mosaic.scss";
@import "02-tablet/03-partials/objects/_events-list.scss";
@import "02-tablet/03-partials/objects/_news-list.scss";
@import "02-tablet/03-partials/objects/_location.scss";
@import "02-tablet/03-partials/objects/_kiosk.scss";
@import "02-tablet/03-partials/objects/_social-list.scss";
@import "02-tablet/03-partials/objects/_waste-management.scss";

// 04-pages

// 05-shame
@import "02-tablet/05-shame/_shame.scss";
}
// @name Smartphone horizontal view [0 ; 480]
@media (max-width: #{$smartphone - 288}px) {
  @import "03-smartphone/03-partials/objects/_news-list.scss";
  @import "03-smartphone/03-partials/objects/_events-list.scss";
}


// @name Smartphone view [0 ; 768]

@media (max-width: #{$smartphone}px) {

// 01-utils

// 02-lib


// 03-partials
@import "03-smartphone/03-partials/_forms.scss";
@import "03-smartphone/03-partials/_buttons.scss";
@import "03-smartphone/03-partials/_wrappers.scss";
@import "03-smartphone/03-partials/_layouts.scss";
@import "03-smartphone/03-partials/_text.scss";
@import "03-smartphone/03-partials/_heading.scss";
@import "03-smartphone/03-partials/_titles.scss";
@import "03-smartphone/03-partials/_sections.scss";
@import "03-smartphone/03-partials/_filters.scss";
@import "03-smartphone/03-partials/_cookies-management.scss";
@import "03-smartphone/03-partials/menus/_mobile-menu.scss";
@import "03-smartphone/03-partials/menus/_menu-cross.scss";
@import "03-smartphone/03-partials/contents/_content.scss";
@import "03-smartphone/03-partials/contents/_content__galeries.scss";
@import "03-smartphone/03-partials/contents/_content__subpages.scss";
@import "03-smartphone/03-partials/contents/_content__go-so-far.scss";
@import "03-smartphone/03-partials/contents/_content__parallax.scss";
@import "03-smartphone/03-partials/contents/_content__typo3.scss";
@import "03-smartphone/03-partials/lists/_list-type-1.scss";
@import "03-smartphone/03-partials/lists/_list-type-1--event.scss";
@import "03-smartphone/03-partials/lists/_list-type-2.scss";
@import "03-smartphone/03-partials/lists/_list-type-3.scss";
@import "03-smartphone/03-partials/lists/_list-service.scss";
@import "03-smartphone/03-partials/singles/_single--event.scss";
@import "03-smartphone/03-partials/objects/_logo.scss";
@import "03-smartphone/03-partials/objects/_sitemap.scss";
@import "03-smartphone/03-partials/objects/_tools.scss";
@import "03-smartphone/03-partials/objects/_breadcrumb.scss";
@import "03-smartphone/03-partials/objects/_filter-content.scss";
@import "03-smartphone/03-partials/objects/_contact-info.scss";
@import "03-smartphone/03-partials/objects/_header-block.scss";
@import "03-smartphone/03-partials/objects/_news-block.scss";
@import "03-smartphone/03-partials/objects/_news-main.scss";
@import "03-smartphone/03-partials/objects/_heading-block.scss";
@import "03-smartphone/03-partials/objects/_option-bar.scss";
@import "03-smartphone/03-partials/objects/_mosaic.scss";
@import "03-smartphone/03-partials/objects/_events-block.scss";
@import "03-smartphone/03-partials/objects/_location.scss";
@import "03-smartphone/03-partials/objects/_kiosk.scss";
@import "03-smartphone/03-partials/objects/_newsletter.scss";
@import "03-smartphone/03-partials/objects/_image-gallery.scss";
@import "03-smartphone/03-partials/objects/_social-block.scss";
@import "03-smartphone/03-partials/objects/_social-list.scss";
@import "03-smartphone/03-partials/objects/_buttons-row.scss";
@import "03-smartphone/03-partials/objects/_extranet-list.scss";
@import "03-smartphone/03-partials/objects/_partners.scss";
@import "03-smartphone/03-partials/objects/_site-infos.scss";
@import "03-smartphone/03-partials/objects/_social-networks.scss";
@import "03-smartphone/03-partials/objects/_fixed-bar.scss";
@import "03-smartphone/03-partials/objects/_list-document.scss";
@import "03-smartphone/03-partials/objects/_info-block.scss";
@import "03-smartphone/03-partials/objects/_twocolumns-grid.scss";
@import "03-smartphone/03-partials/objects/_media-twocolumns.scss";
@import "03-smartphone/03-partials/objects/_waste-management.scss";
// 04-pages

// 05-shame
@import "03-smartphone/05-shame/_shame.scss";
}


// @name Mini Smartphone view [0 ; 460]

@media (max-width: #{$minismartphone}px) {

// 01-utils

// 02-lib

// 03-partials
@import "04-minismartphone/03-partials/objects/_header-block.scss";
// 04-pages

// 05-shame
}
