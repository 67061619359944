.news-main {
	margin: 0 -10px 36px;
	padding: 0;
	overflow: visible;
	&-img {
		width: 100%;
	}
	&-description {
		width: 100%;
		margin-top: -15px;
		padding: 33px 21px 28px;
		&:before {
			left: -10px;
		}
	}
	&-category {
		padding: 6px 22px;
		margin: 0 0 13px -21px;
		min-width: 0;
	}
	&-title {
		font-size: 2.5rem;
		line-height: 1.2;
		margin: 0 0 7px;
	}
	p {
		font-size: 1.6rem;
	}
}