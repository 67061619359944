.social-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px 12px;
	li {
		padding: 0 10px 20px;
		width: 20%;
		&.tablet-two-line{
			width: 20%;
		}
	}
	&-post {
		background: #fff;
		position: relative;
		&-content {
			border: 1px solid #dedede;
			padding: 20px 19px 25px;
		}
	}
	&-img {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&-btn {
		display: block;
		height: 34px;
		background: #3b5998;
		position: relative;
		span {
			position:absolute;
			top:50%;
			left:50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
	&-title {
		font-size: 1.4rem;
		line-height: 1.2;
		color: #3b5998;
		font-weight: 700;
		display: block;
		overflow: hidden;
		margin: 0 0 21px;
		&-img {
			float: left;
			margin: 0 11px 0 0;
		}
		&-text {
			overflow: hidden;
			display: block;
			padding-top: 13px;
		}
	}
	p {
		color: #154452;
		font-size: 1.3rem;
		line-height: 1.2;
		margin: 0 0 9px;
		font-weight: 300;
	}
	&-date {
		display: block;
		color: #3b5998;
		font-size: 1rem;
		line-height: 1.2;
		font-weight: 300;
		text-transform: uppercase;
	}
}