.access__title {
  border: 1px dotted $color-black;
}
.access-tooltip {
  position: absolute;
  background: $color-1--3;
  border: 1px solid gray;
  border-radius: 3px;
  padding: em(1, 1.3);
  color: $color-white;
  font-size: 1.3em;
  max-width: 30vw;
  &:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 0 12px;
    border-color: transparent transparent transparent $color-1--3;
  }
}