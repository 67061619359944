//@name Page content

// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

// @name Title h2
$content-h2__font-size: 3.4;
.content h2, .h2 {
    font-family: $typo-3;
    font-size: #{$content-h2__font-size}em;
    line-height: 1em;
    font-weight: $bold;
    color: $color-1--2;
    margin: em(6, $content-h2__font-size) 0 em(2.5, $content-h2__font-size) 0;
    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

.content h2.title-content {
    font-size: 30px;
    font-weight: normal;
    text-transform: uppercase;
    color: #201d1d;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 41px;
    &:after {
        background-color: #5090c8;
        display: block;
        content: '';
        width: 36px;
        height: 6px;
        position: absolute;
        left: 0;
        top: 100%;
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.content h3, .h3 {
    font-family: $typo-3;
    font-size: #{$content-h3__font-size}em;
    line-height: 1em;
    font-weight: $bold;
    color: $color-5--1;
    margin: em(5, $content-h3__font-size) 0 em(1.7, $content-h3__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2;
.content h4, .h4 {
    font-family: $typo-3;
    font-size: #{$content-h4__font-size}em;
    line-height: 1em;
    color: $color-black;
    margin: em(4, $content-h4__font-size) 0 em(1.8, $content-h4__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.content h5, .h5 {
    font-family: $typo-3;
    font-size: #{$content-h5__font-size}em;
    line-height: 1em;
    color: $color-black;
    margin: em(2.5, $content-h5__font-size) 0 em(3.2, $content-h5__font-size) 0;
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.content h6, .h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1em;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.4;
.content {
    // @name Reset first element
    // @description Reset margin top for first element.
    > *:first-child {
        margin-top: 0;
    }
    &.blocHeader {
        h2 {
            margin-top: em(6, $content-h2__font-size);
        }
    }

    // @name Link
    a {
        color: $color-1--2;
        text-decoration: underline;
        &.anchor {
            @include default-icons-after('\e086', 0 0 0 5px, 8px, #572381, middle);
            &:after {
                font-size: 12px;
            }
        }
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e086', 0 0 0 5px, 8px, #572381, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.4;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: 300;
        line-height: 1.1em;
        margin: em(.4, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
    }
    .ce-textpic p {
        line-height: 1.3em;
    }
    .ce-intext.ce-left .ce-gallery {
        margin-top: 10px;
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.4em;
        color: #666666;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        $encadre__font-size: 1.5;
        padding: em(2, $encadre__font-size) 25px;
        font-size: 1.6em;
        line-height: 1.2em;
        background: #caddee;
        border: 1px solid $color-5--1;
        margin: em(1.2, $encadre__font-size) 31px em(1.9, $encadre__font-size) 29px;
        a {
            font-weight: bold;
            color: #000;
        }
    }

    // @name Blockquote
    $content-blockquote__font-size: 1.8;
    $content-blockquote-icon-1__font-size: 3.2;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        @include default-icons-absolute-before('\e06f', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-5--1, 6px, inherit, inherit, em(-4, $content-blockquote-icon-1__font-size));
        &:before {
            font-style: normal;
        }
        padding: em(1, $content-blockquote__font-size) 0 em(0.5, $content-blockquote__font-size) em(2, $content-blockquote__font-size);
        padding-right: 42px;
        font-weight: $light;
        font-style: italic;
        margin: em(2.5, $content-blockquote__font-size) 0 em(2.2, $content-blockquote__font-size) em(3.8, $content-blockquote__font-size);
        color: #727272;
        border-left: 1px solid #727272;
        p {
            font-size: 1em;
            line-height: 1.2;
            font-weight: $light;
        }
    }

    // @name Unordered list
    ul, .show-hide__content ul {
        margin: em(2, $content-list__font-size) 0 em(1.5, $content-list__font-size) em(2.5, $content-list__font-size);
        list-style: none;
        font-size: #{$content-list__font-size}em;
        color: $color-black;
        padding: 0;
        > li {
            @include default-icons-absolute-before('\e027', em(1, $content-list__font-size), $color-5--1, em(.4, 1.4), inherit, inherit, 0);
            padding-left: em(1.2, $content-list__font-size);
            margin-bottom: 9px;
            button, .button-1, .button-1.button-1--small, .button-1.button-1--big, .button-2, .button-2.button-2--small, .button-2.button-2--big {
                font-size: 1em;
            }
            > ul:first-child, > a + ul { // for specificity selector
                font-size: 1em;
                margin: 0 0 em(1, $content-list__font-size) em(.8, $content-list__font-size);
                list-style: none;
                padding: em(.1, $content-list__font-size) 0 em(.1, $content-list__font-size) 0;
                > li {
                    @include default-icons-absolute-before('\e007', em(1, $content-list__font-size), $color-black, em(.5, 1), inherit, inherit, 0);
                    padding-left: em(1.2, $content-list__font-size);
                    margin-bottom: 0;
                }
            }
            > ol:first-child, > a + ol { // for specificity selector
                margin: 0 0 em(1, $content-list__font-size) em(2.5, $content-list__font-size);
                padding: em(.1, $content-list__font-size) 0 em(.3, $content-list__font-size) 0;
                font-size: 1em;
                counter-reset: point;
                > li {
                    padding: 0;
                    margin-bottom: 4px;
                    position: relative;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                    &:after {
                        content: counter(point) ".";
                        counter-increment: point 1;
                        font-size: 14px;
                        position: absolute;
                        left: -14px;
                        font-weight: $normal;
                        top: 0;
                        color: $color-black;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: em(2, $content-list__font-size) 0 em(1.5, $content-list__font-size) em(2.5, $content-list__font-size);
        padding: 0 0 0 0;
        list-style: decimal;
        font-size: #{$content-list__font-size}em;
        color: $color-black;
        list-style: none;
        counter-reset: item;
        > li {
            padding: 0;
            position: relative;
            padding-left: 17px;
            margin-bottom: 15px;
            &:after {
                content: counter(item) ".";
                counter-increment: item 1;
                font-size: 14px;
                position: absolute;
                left: 0;
                top: 0;
                color: #5090c8;
            }
            button, .button-1, .button-1.button-1--small, .button-1.button-1--big, .button-2, .button-2.button-2--small, .button-2.button-2--big {
                font-size: 1em;
            }
            > ol:first-child, > a + ol { // for specificity selector
                margin: 0 0 em(1, $content-list__font-size) em(1.5, $content-list__font-size);
                padding: em(.1, $content-list__font-size) 0 em(.1, $content-list__font-size) 0;
                font-size: 1em;
                counter-reset: point;
                > li {
                    padding: 0;
                    position: relative;
                    margin-bottom: 4px;
                    &:after {
                        content: counter(point) ".";
                        counter-increment: point 1;
                        font-size: 14px;
                        position: absolute;
                        left: -14px;
                        font-weight: $normal;
                        top: 0;
                        color: $color-black;
                    }
                }
            }
            > ul:first-child, > a + ul { // for specificity selector
                margin: 0 0 em(1, $content-list__font-size);
                list-style: none;
                padding: em(.1, $content-list__font-size) 0 em(.3, $content-list__font-size) 0;
                font-size: 1em;
                > li {
                    margin-bottom: 4px;
                    @include default-icons-absolute-before('\e007', 10px, $color-black, 4px, inherit, inherit, 0);
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 2em 0;
    }
    .table-wrapper-inner {
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.2;
            font-family: $typo-2;
            text-align: right;
            font-weight: $normal;
            font-size: #{$table__caption_font-size}em;
            font-style: italic;
            color: #777777;
            padding-right: 15px;
            padding-bottom: 6px;
            position: relative;
            margin-bottom: 0;
            @include default-icons-absolute-before("\e00e", $size: 14px, $color: #623289, $right: 0, $top: 0);
            &:before {
                font-style: normal;
            }
            &:after {
                background-color: #afafaf;
                display: block;
                content: "";
                width: 100%;
                height: 1px;
                max-width: 560px;
                position: absolute;
                right: 0;
                top: 100%;
            }
        }
        th, td {
            padding: 12px 14px;
            border-bottom: 1px solid #afafaf;
        }
        th {
            font-size: 1.4em;
            text-transform: uppercase;
            font-family: $typo-3;
            font-weight: $bold;
            color: $color-6--1;
            text-align: left;
        }

        thead th {
            font-size: 1.3em;
            background: $color-white;
            font-weight: $bold;
            color: $color-1--2;
            border-bottom: 4px solid $color-2--2;
        }
        tbody th {
            text-align: right;
            padding-left: 6px;
            padding-right: 50px;
            font-size: 1.3em;
            color: #5090c8;
        }
        td {
            font-size: 1.4em;
            font-weight: normal;
            color: $color-black;
            background: $color-white;
        }
    }

    // @name column 2
    .column--2, .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
    .caption-video {
        font-size: 12px;
        font-style: italic;
        color: #727272;
        position: relative;
        padding-right: 11px;
        text-align: right;
        margin-top: -18px;
        @include default-icons-absolute-before("\e00f", $size: 14px, $color: #623289, $right: 0, $top: 0);
        &:before {
            font-style: normal;
        }
    }
}

.files-content {
    background-color: #f9f9f9;
    padding: 30px 40px;
    margin-bottom: 59px;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    .file-column {
        float: left;
        width: 50%;
        & + .file-column {
            border-left: 1px solid #afafaf;
            padding-left: 40px;
        }
    }
    .file-row {
        margin-bottom: 23px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            color: #000;
            text-decoration: none;
            display: inline-block;
            vertical-align: top;
            &:hover {
                text-decoration: underline;
            }
            &:focus {
                outline: 1px dashed #000;
                outline-offset: -1px;
            }
        }
    }
    .icoWrap {
        float: left;
        margin-right: 15px;
        svg {
            width: 31px;
            height: 39px;
        }
    }
    .fileName {
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        position: relative;
        margin-top: -5px;
        max-width: 240px;
        span {
            font-size: 12px;
        }
    }
}
