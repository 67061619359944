.waste-management {
  $this: &;
  $filter: '.filters';

  &__heading {
    background-color: $color-1--5;
    padding: 4rem 2rem 2.5rem;
  }

  &__filter {
    background-color: transparent;

    form {
      margin: 1rem 0 0 0;

      label {
        font-size: 1.6rem;
        font-weight: $light;
      }
    }

    #{$filter}__button-wrapper {
      padding-top: 3.5em;
      padding-left: 2em;
    }
  }

  &__main {
    background-color: $color-3--10;
    padding: 7rem 1rem 3rem;
  }

  &__zone {
    margin-bottom: 4rem;

    .ce-textpic .ce-gallery {
      margin: 1rem 0;
    }

    .ce-column {
      margin-right: 6rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__footer {
    margin-top: 7.5rem;
    text-align: center;
  }
}
