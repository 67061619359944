.filter-content  {
  margin-top: 0;
  .tabset  {
    float: none;
    text-align: center;
    margin-left: -7px;
    margin-right: -7px;
    li {
      display: inline-block;
      float: none;
      margin: 0 3px;
      a {
        width: 144px;
      }
    }
  }
  .filters__fields.filters__fields--column-3 {
    .filters__fields-wrapper {
      > .filters__field-wrapper {
        flex-basis: 46%;
      }
    }
  }
}
