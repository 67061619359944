.content {
  .subpages-menu {
    margin: 2em 0 3em;
    a {
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
  .subpages-menu__listitems {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -2%;
    > * {
      flex: 0 1 29.3333%;
      margin: 0 2% 3em;
    }
  }
  .subpages-menu__picture {
    display: block;
    img {
      max-width: 100%;
      height: auto;
    }
    margin-bottom: 2em;
  }
  .subpages-menu__header {
    border-bottom: 1px solid $color-3--3;
    position: relative;
  }
  .subpages-menu__title {
    $subpages-menu__title__font-size: 2;
    margin: 0 0 em(.8, $subpages-menu__title__font-size) 0;
    font-size: #{$subpages-menu__title__font-size}em;
    color: $color-black;
    border: none;
    a {
      color: $color-black;
    }
  }
  .subpages-menu__listitems2 {
    margin: em(1.5, 1.4) 0 em(1.5, 1.4) 0;
  }
  .subpages-menu__item2 {
    text-transform: uppercase;
    color: $color-black;
    margin: .5em 0;
    &:before {
      content: "\e027";
      color: $color-2--2;
    }
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
}