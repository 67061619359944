.equipements-list,
.content .equipements-list {
	margin: 0;
	color: #000;
	ul {
		margin: 0;
	}
	li {
		padding-left: 15px;
		&:not(:first-child) {
			margin-top: 11px;
		}
		&:before {
			font-size: 15px;
		}
		h2 {
			font-size: 2rem;
			line-height: 1.2;
			color: #000;
			font-weight: 700;
			margin: 0;
		}
		a {
			color: #000;
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}