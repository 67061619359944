// CSS object

// icon fonts
.icons-default,
.icons-project {
  content: "\e001";
  font-family: 'icons-default';
  font-weight: $normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  speak: none;
}

.icons-project {
  font-family: 'icons-project';
}

// Invisible content but accessible
.ghost {
  position: absolute !important;
  top: auto !important;
  left: -9999px !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

.hidden {
  display: none;
}

// Clear after element
.clear-fix {
  &:after {
    clear: both;
    display: block;
    content: "";
    overflow: hidden;
    visibility: hidden;
    height: 0;
    speak: none;
  }
}

.clear-fix-before{
  &:before{
    clear: both;
    display: block;
    content: ""!important;
    overflow: hidden;
    visibility: hidden;
    height: 0;
    speak: none;
  }
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}