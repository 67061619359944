.location {
	&-block {
		padding: 0;
	}
	.heading-block {
		margin: 0 0 12px;
	}
	&-popup {
		&-wrap {
			width: 240px;
			margin: 0 auto 41px;
			float: none;
		}
		&-title {
			font-size: 2.8rem;
			margin: 0 0 35px;
			strong {
				font-size: 6rem;
				margin: 0 0 7px;
			}
		}
	}
	&-maps {
		width: 292px;
		margin: 0 auto;
		&-twocolumns {
			float: none;
			padding: 0;
			width: 100%;
		}
		&-wrap {
			width: 100%;
			float: none;
			margin: 0 0 26px;
		}
		&-area {
			bottom: 22px;
			right: 3px;
			width: 70px;
		}
	}
	&-list {
		display: none;
	}
	&-select {
		padding: 0 50px 0 0;
		position: relative;
		display: block;
		.button-2 {
			padding: 0;
			border: none;
			width: 40px;
			height: 40px;
			position:absolute;
			bottom: 0;
			right: 0;
			background: #5090c8;
			overflow: hidden;
			&:before {
				font-weight: 400;
				color: #fff;
				font-size: 18px;
				bottom: auto;
			}
		}
	}
	&-info {
		width: 100%;
		float: none;
		margin: 25px 0 0;
	}
}