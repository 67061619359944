.contact-info  {
  padding-left: 10px;
  padding-right: 10px;
  .contact-info-block {
    width: 100%;
    & + .contact-info-block {
      padding-left: 0;
    }
  }
  .button {
    display: block;
    width: 154px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}