.contact-info {
  background-color: #f9f9f9;
  padding: 20px 20px 15px;
  margin-bottom: 23px;
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 28px;
  }
  p {
    line-height: 1.3;
    margin-bottom: 7px;
  }
  .contact-info-holder {
    overflow: hidden;
  }
  .contact-info-block {
    float: left;
    width: 50%;
    & + .contact-info-block {
      padding-left: 50px;
      p {
        color: #3c3c3c;
      }
    }
  }
  .button {
    font-size: 12px;
    line-height: 1;
    padding: 15px 20px 15px 47px;
    margin-top: 4px;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none;
  }
  .button-inter {
    &:before {
      content: '';
      background: url("../Images/content/ico-arrow.png") 0 0 no-repeat;
      width: 14px;
      height: 10px;
    }
  }
}