// cookies top block
#cookieManager {
	padding: 20px 15px 10px;
	.cookieManager-header p:not(.title) {
		padding: 0;
	}
	form {
		.validate {
			button {
				margin-bottom: 10px;
			}
		}
	}
}
