.social-list {
	margin-left: -5px;
	margin-right: -5px;
	li {
		width:33.33%;
		&.tablet-two-line{
			width:50%;
		}
		padding-left: 5px;
		padding-right: 5px;
	}
	&-post-content {
		padding: 15px 10px;
	}
}