.list-service {
	font-size: 0;
	line-height: 0;
	margin: 0 -5px;
	text-align: center;
	li {
		display:inline-block;
		vertical-align:top;
		padding: 0 5px 10px;
		a {
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
}