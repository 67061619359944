.collaborator-list,
.content .collaborator-list {
	&-item {
		background: #f4f4f4;
		padding: 20px 31px;
		overflow: hidden;
	}
	&-img {
		float: left;
		margin: 0 30px 0 0;
		padding: 3px 0 0;
	}
	&-content {
		overflow: hidden;
	}
	.subtitle2 {
		margin: 0 0 3px;
		span {
			text-transform: none;
		}
	}
	&-title {
		font-size: 2rem;
		line-height: 1.2;
		color: #000;
		margin: 0 0 14px;
		a {
			color: #000;
			text-decoration: none;
			&:hover,
			&:focus {
				color: #000;
				text-decoration: underline;
			}
		}
	}
	&-option {
		margin: 0;
		li {
			margin: 0;
			padding: 0;
			&:before {
				display: none;
			}
			a {
				color: #000;
				font-size: 1.4rem;
				line-height: 1.2;
				text-decoration: none;
				display:inline-block;
				vertical-align:top;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}