.filter-content {
  .filters__fields.filters__fields--column-3 {
    .filters__fields-wrapper {
      > .filters__field-wrapper {
        & + .filters__field-wrapper {
          margin-left: 0;
        }
      }
    }
  }
}