// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications {
  .contact {
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 21px;
  }
}
.bloc-publications__item {
  .list-type-2__picture--2 {
    display: block;
    position: relative;
    margin-bottom: 20px;
    height: 15.3em;
    img {
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .bloc-publications__title2 {
    font-size: 16px;
    text-align: center;
    color: #282d33;
    margin-bottom: 15px;
    a {
      color: #282d33;
    }
    span {
      display: block;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .description {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    color: #282d33;
    margin-bottom: 20px;
  }
}
.bloc-elected__picture {
  img {
    display: block;
    margin: 0 auto;
  }
}
.bloc-publications__picture {
  display: block;
  float: left;
  img {
    margin-right: 2em;
  }
  .list-type-2__picture {
    width: 100%;
  }
}
.bloc-publications__wrapper {
  overflow: hidden;
}
.bloc-publications__title {
  $bloc-publications__title__font-size: 2.4;
  font-size: #{$bloc-publications__title__font-size}em;
  font-family: $typo-3;
  font-weight: $bold;
  margin: 0 0 em(.8, $bloc-publications__title__font-size);
  color: $color-black;
  a {
    color: $color-black;
    &:hover, &:focus {
      color: $color-1--2;
    }
  }
}
.column-bloc {
  .bloc-publications__title {
    $bloc-publications__title__font-size: 1.6;
    font-size: #{$bloc-publications__title__font-size}em;
    margin: 0 0 em(.8, $bloc-publications__title__font-size);
  }
}



