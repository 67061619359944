// Galerie classique (avec vignettes) + Galerie album
.content {
  .classical-gallery {
    margin-right: 0;
    margin-left: 0;
    position: relative;
    &__title {
      padding: 0 10px;
    }
    &__nb-items {
      display: block;
      padding: 0 10px;
      @include background-opacity($color-black, .8);
      color: $color-white;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 3em;
      margin: 0;
    }
  }
  .slider-galerie {
    margin-bottom: 0;
    &__item {
      height: auto;
      display: none;
      &:first-child {
        display: block;
      }
    }
    .infos-img {
      display: block;
      right: inherit;
      bottom: inherit;
      width: 100%;
    }
  }
  .carousel-galerie__thumb  {
    display: none;
  }
  .carousel-galerie {
    position: static;
    margin: 0;
    padding: 0;
    height: auto;
  }
  .carousel-galerie__pager {
    position: static;
    height: auto;
  }
  .carousel-galerie__prev,
  .carousel-galerie__next {
    position: static;
    margin: 0;
    button {
      background: rgba(151, 185, 15, .7);
      width: 24px;
      position: absolute;
      height: 100%;
      z-index: 100;
      left: 0;
      top: 0;
      &:before {
        font-size: 1em;
      }
    }
  }
  .carousel-galerie__next {
    button {
      right: 0;
      left: auto;
    }
  }
}