.news-main {
	&-description {
		padding: 25px 21px 10px;
	}
	&-title {
		font-size: 3rem;
	}
	p {
		font-size: 1.4rem;
	}
}