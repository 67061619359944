// import google font
@if variable-exists(import-google-font-1) {
  @import url($import-google-font-1);
}
@if variable-exists(import-google-font-2) {
  @import url($import-google-font-2);
}
@if variable-exists(import-google-font-3) {
  @import url($import-google-font-3);
}

// Icon fonts
// create font-face for icon fonts
@include fontFace(icons-default, 'default-icons-webfont', normal, normal);
@include fontFace(icons-project, 'icons', normal, normal);

// @name Body
body {
  font-family: $typo-1;
  font-weight: $normal;
  overflow-x: hidden;
	background: white;
}

// hide SVG symbol
.svg-symbol {
  display: none;
}

.link-block {
	&:after {
		position:absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		z-index: 1;
		content: '';
	}
	&-wrap {
		position: relative;
	}
}