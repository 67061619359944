// @name Main section wrappers
.section-main__wrapper {
  width: 100%;
  padding-top: 3em;
  padding-bottom: 3em;
}

.section-main__wrapper-2 {
  display: flex;
  flex-direction: column;
  > * {
    display: block;
    vertical-align: inherit;
  }
}

.section-main__content {
  margin-bottom: 2rem;
}

.header,
.home .header {
  padding: 9px 10px 19px;
  min-height: 0;
  &:before {
    height: 9px;
  }
  &-shadow {
    &:before,
    &:after {
      box-shadow: 0 15px 5px 0 rgba(0, 0, 0, 0.2);
      left: -10px;
      top: -20px;
      width: 50%;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
    }
    &:before {
      left: auto;
      right: -10px;
      -webkit-transform: rotate(4deg);
      -ms-transform: rotate(4deg);
      transform: rotate(4deg);
    }
  }
}

.top-of-content__wrapper {
  padding-top: 20px;
}

// @name Footer
.footer {
  &:after {
    top: 9px;
  }
  &__wrapper {
    padding: 32px 0 28px;
  }
  .newsletter {
    float: none;
    width: auto;
    padding: 28px 0;
    &:after {
      display: none;
    }
    &__links {
      text-align: left;
      padding-bottom: 19px;
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      li {
        float: left;
        margin: 0 17px 0 0;
      }
    }
  }
}
