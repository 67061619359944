// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .bloc-contact__title {
    $bloc-contact__title__font-size: 1.6;
    font-family: $typo-3;
    font-weight: $bold;
    font-size: 2em;
    margin: 0;
    color: $color-black;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
  .list-infos {

  }
  .list-infos__wrapper {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    > * {
      flex: inherit;
    }
    .list-infos__listitems + .list-infos__listitems {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }
  }
  .button-2 {
    margin-top: em(1, $button-2__font-size);
    &:before {
      content: "\e02a";
    }
  }
  .contact {
    margin-bottom: 10px;
  }
}


