// Focus
a, button, input[type="button"] {
  &:focus {
    outline: 1px dashed $color-1--2;
  }
}

// @name link-bloc
// @description Create an ergonomic link
.link-bloc__context {
	position: relative;
	.link-bloc {
		&:after {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			content: "";
			speak: none;
      z-index: 10;
		}
	}
}

// @name Link view
// @description Link under feature (RSS of the feature + list of the feature)
.link-view {
  $link-view__item__font-size: 1.2;
  .button-1 {
    &:before {
      content: "\e027";
    }
  }
}

.column-bloc {
  .link-view {
    margin-top: 2em;
  }
}