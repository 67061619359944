.block-files {
  h3 {
    margin-bottom: 30px;
  }
  .file-row {
    border-bottom: 1px solid #afafaf;
    overflow: hidden;
    padding-bottom: 10px;
    margin-bottom: 10px;
    a {
      color: #000;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .icoWrap {
    float: left;
    margin-right: 13px;
    padding-top: 5px;
    svg {
      width: 31px;
      height: 39px;
    }
  }
  .fileName {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    position: relative;
    overflow: hidden;
    span {
      font-size: 12px;
    }
  }
}