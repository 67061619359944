.media-twocolumns,
.content .media-twocolumns {
	.classical-gallery-small {
		float: none;
		width: 100%;
		margin: 0 0 60px;
	}
	.maps-wrap {
		width: 100%;
		float: none;
	}
}