.info-block {
	background: #f4f4f4;
	margin: 0 0 35px;
	&-img {
		text-align: center;
		img {
			display: inline-block;
			vertical-align: top;
		}
	}
	&-content {
		padding: 32px 39px 14px;
		display: flex;
	}
	&-address {
		width: 27.7%;
		padding: 0 20px 0 0;
	}
	&-contact {
		width: 34.5%;
		padding: 0 20px 0 0;
	}
	&-description {
		width: 37.8%;
		p {
			line-height: 1.2;
			margin: 0 0 22px;
		}
	}
	.location-info-list {
		margin: 0;
		li {
			padding: 0;
			margin: 0 0 18px;
			&:before {
				display: none;
			}
		}
	}
	.list-infos__item {
		margin-top: 32px;
		&:first-child {
			margin-top: 0;
		}
	}
}