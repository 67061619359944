.extranet-list,
.content .extranet-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;

	li {
		width: 33.3%;
		padding: 0 10px; 
		position: relative;

		&:before {
			display: none;
		}
	}

	&-title {
		color: #000;
		font-size: 2rem;
		line-height: 1;
		font-weight: 700;
		margin: 0 0 18px;
		a {
			color: #000;
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
				color: #000;
			}
		}
	}

	p {
		font-size: 1.4rem;
		font-weight: 300;
		color: #000;
	}
}