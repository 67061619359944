// @name Content list 1
.list-content-1, .content .list-content-1 {
  $list-content-1__font-size: 1.4;
  font-size: 1em;
  > li {
    text-transform: uppercase;
    font-size: #{$list-content-1__font-size}em;
    font-family: $typo-2;
    font-weight: $light;
    margin: em(0.5, $list-content-1__font-size) 0;
    @include default-icons-before('\e027', 0 5px 0 0, em(1.2, $list-content-1__font-size), $color-1--2, initial);
    > a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
}

// @name Content list 2
// @description You can see this list on content page .go-so-far.
.list-content-2, .content .list-content-2 {
  $list-content-2__font-size: 1.6;
  font-size: 1em;
  > li {
    font-size: #{$list-content-2__font-size}em;
    font-family: $typo-2;
    font-weight: $light;
    margin: em(0.63, $list-content-2__font-size) 0;
    padding-left: em(1.7, $list-content-2__font-size);
    @include default-icons-absolute-before('\e027', em(1.2, $list-content-2__font-size), #5090c8, em(.4, 1.2), inherit, inherit, em(0, 1.2));
    > a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
}