// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .bloc-event__wrapper-date {
    margin-bottom: 2.6em;
  }
  .bloc-event__title {
    $bloc-event__title__font-size: 2;
    font-family: $typo-3;
    font-weight: normal;
    font-size: #{$bloc-event__title__font-size}em;
    margin: 0 0 em(2.4, $bloc-event__title__font-size);
    color: $color-black;
    text-align: center;
    line-height: 1.28;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
    em {
      display: block;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    span {
      display: block;
      padding-top: 24px;
      font-size: 13px;
      font-weight: 300;
      color: #6072ac;
      text-transform: uppercase;
      position: relative;
      &:before {
        background-color: #7b89b8;
        display: block;
        content: '';
        width: 72px;
        height: 1px;
        position: absolute;
        left: 50%;
        top: 20px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
  .date-1 {
    margin-top: -34px;
  }
  .hour-place {
    text-align: left;
  }
  .button-toutes {
    color: #6072ac;
    &:before {
      color: #6072ac;
    }
  }
}