.partners {
	padding: 30px 0;
	&-carousel {
		padding: 0 44px;
		position: relative;
		.listItems {
			width: 100%;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
		}
		.item {
			padding: 0 12px;
			text-align: center;
			width: 100%;
			max-width: 243px;
		}
		&-img {
			height: 118px;
			position: relative;
			img {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
		}
		&-title {
			display: block;
			background: #f9f9f9;
			padding: 7px 10px;
			color: #282d33;
			font-size: 1.3rem;
			line-height: 1.2;
			font-weight: 300;
		}
		.prevCaroussel3,
		.nextCaroussel3 {
			position:absolute;
			top:68px;
			width: 40px;
			height: 40px;
			img {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
			button {
				background: none;
				border: none;
				width: 100%;
				height: 100%;
			}
		}
		.prevCaroussel3 {
			left: -13px;
		}
		.nextCaroussel3 {
			right: -13px;
		}
	}
}