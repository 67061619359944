// @name site informations
.site-infos {
	float: right;
	width: 43%;
	padding: 0 0 0 7%;
	address {
		font-size: 1.3rem;
		line-height: 1.2;
		color: #fff;
		margin: 0 0 10px;
		font-weight: 300;
		font-style: normal;
		text-transform: uppercase;
		strong {
			display: block;
			font-weight: 700;
		}
	}
	&-contact {
		&:after{
			content:"";
			display:block;
			clear:both;
		}
		.btn-phone {
			float: left;
			margin: 0 0 10px;
		}
		.btn-email {
			float: right;
		}
	}
}

.footer-logo {
	width: 220px;
	display: block;
	margin: -10px 0 13px -36px;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}