// @name Comments

.comments__listitems--children {
  .comment {
    padding-left: 30px;
  }
  .comment__wrapper-2 {
    &:after {
      border-color: transparent transparent $color-white transparent;
    }
  }
}

.comment__avatar {
  float: none;
  margin-right: 0;
  margin-bottom: 1em;
}

.comment__wrapper {
  padding-left: 0;
  padding-top: 12px;
}

.comment__wrapper-2 {
  &:before, &:after {
    right: inherit;

  }
  &:before {
    top: -24px;
    left: 28px;
    border-color: transparent transparent $color-3--2 transparent;
  }
  &:after {
    top: -20px;
    left: 30px;
    border-color: transparent transparent $color-3--1 transparent;
  }
}

.comment__reply-link {
  position: static;
  top: inherit;
  right: inherit;
}