// @name Header page
.page {
	overflow: hidden;
	position: relative;
}

.home .header {
	padding: 0 32px 0 0;
	@media (min-width: 961px) and (max-width: 1170px) {
		padding: 0;
	}
}

.header {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 84px 0 0;
	position: relative;
	z-index: 15;
	&:after {
		position:absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background: #fff url(../Images/bg/bg-decor-02.png) no-repeat 72px 0;
		display: block;
		z-index: -1;
		content: '';
	}
	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 7px;
		width: 100%;
		background: #acc600 url(../Images/bg/bg-decor-01.png) no-repeat 50% 0;
		content: '';
	}
	&-wrap {
		@extend .clear-fix;
	}
	&-shadow {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 10px;
		z-index: -2;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
		&:after,
		&:before {
			width: 40%;
			height: 20px;
			left: 20px;
			position: absolute;
			top: -17px;
			box-shadow: 0 15px 8px 0 rgba(0, 0, 0, 0.3);
			-webkit-transform: rotate(-2deg);
			-ms-transform: rotate(-2deg);
			transform: rotate(-1.5deg);
			content: '';
		}
		&:before {
			left: auto;
			right: 20px;
			-webkit-transform: rotate(2deg);
			-ms-transform: rotate(2deg);
			transform: rotate(1.5deg);
		}
	}
}

// @name Top of content
.top-of-content {
	background: #fff;
	position: relative;
	z-index: 1;
	&:after {
		position:absolute;
		top:-43px;
		left: -50px;
		right: -50px;
		-webkit-transform: rotate(-2.3deg);
		-ms-transform: rotate(-2.3deg);
		transform: rotate(-2.3deg);
		height: 86px;
		background: #fff;
		z-index: -1;
		content: '';
	}
	&-gray {
		background: #f9f9f9;
		&:after {
			background: #f9f9f9;
		}
	}
}

.top-of-content__wrapper {
	@extend .wrapper-940;
	padding-top: 0.2em;
	padding-bottom: 2em;
}

// @name Main wrapper
.main {
	width: 100%;
	clear: both;
}

// @name Main section
.section-main {}

// @name Main section wrappers
.section-main__wrapper {
	@extend .wrapper-940;
	padding-top: 6.3em;
	padding-bottom: 3em;
	&_mrgn-top {
		padding-top: 0 !important;
	}
	&.section-main__wrapper-map {
		padding: 2em 0 6em;
	}
}
.section-main__wrapper-map {
	.map {
		img {
			display: block;
			width: 100%;
		}
	}
}

.section-main__wrapper-2 {
	display: flex;
}

// @name Main section content
.section-main__content {
	width: 100%;
}

// @name Main section aside
.section-main__aside {
	> .column-bloc {
		*:first-child {
			margin-top: 0;
		}
	}
	.column-bloc {
		.button-paiment {
			padding: 12px 29px 12px 44px;
		}
	}
}

// @name Search layout
// @description Specific layout for search page, filter to the left.
.page_id_98 {
    .section-main__content {
        order: 2;
    }
    .section-main__aside {
        order: 1;
        .column-bloc {
            padding-left: 0;
            padding-right: 6em;
        }
    }
}

// @name Footer
.footer {
	background: #97b911;
	-webkit-transform: rotate(-0.4deg);
	-ms-transform: rotate(-0.4deg);
	transform: rotate(-0.4deg);
	//margin-left: -50px;
	//margin-right: -50px;
	//padding: 0 50px;
	position: relative;
	z-index: 10;
	margin-bottom: -10px;
	overflow: hidden;
	&:after {
		background: #697cb7 url(../Images/bg/bg-decor-06.png) no-repeat 50% -20px;
		-webkit-transform: rotate(0.7deg);
		-ms-transform: rotate(0.7deg);
		transform: rotate(0.7deg);
		position: absolute;
		top: 15px;
		bottom: -50px;
		left: 0;
		width: 100%;
		z-index: -1;
		content: '';
	}
	a {
		&:focus {
			outline-color: #fff;
		}
	}
	&__wrapper {
		padding: 65px 0 50px;
		-webkit-transform: rotate(0.4deg);
		-ms-transform: rotate(0.4deg);
		transform: rotate(0.4deg);
		&-2 {
			max-width: 1115px;
			padding: 0 10px;
			margin: 0 auto;
			&:after {
				content: "";
				display: block;
				clear: both;
			}
		}
	}
	.newsletter {
		float: left;
		background: none;
		width: 29.8%;
		padding: 0 7.4% 0 0;
		position: relative;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		&:after {
			width: 1px;
			background: #8796c5;
			position: absolute;
			top: -999px;
			right: 0;
			bottom: -999px;
			content: '';
		}
		&__title {
			font-size: 1.8rem;
			color: #fff;
			margin: 0 0 17px;
		}
		label {
			text-transform: none;
			color: #fff;
			font-size: 1.3rem;
			font-weight: 300;
			margin: 0 0 7px;
		}
		&__fields {
			padding-right: 44px;
			position: relative;
			margin: 0 0 11px;
			&-wrapper {
				margin: 0;
			}
		}
		input {
			border: none;
			background: none;
			height: 40px;
			padding: 0 12px;
			font-size: 13px;
			color: #fff;
			font-style: normal;
			border: 1px solid #fff;
			&:focus {
				outline-color: #fff;
			}
		}
		&__button-wrapper {
			position: absolute;
			bottom: 0;
			right: 0;
			.button-2 {
				background: #97b911;
				width: 45px;
				height: 40px;
				border: none;
				border: 1px solid #fff;
				padding: 0;
				svg {
					width: 18px;
					height: 16px;
				}
				&:focus {
					outline-color: #fff;
				}
			}
		}
		&__links {
			margin: 0;
			padding: 0 0 12px;
			display: block;
			li {
				margin: 0 0 8px;
				display: block;
			}
			a {
				font-size: 1.2rem;
				line-height: 1.2;
				color: #fff;
				font-weight: 300;
				padding: 0 0 0 14px;
				&:after {
					content: '\e027';
				}
			}
		}
	}
}

#js-modal-page {
	display: flex;
	width: 100%;
	flex-wrap:wrap;
}

.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
}

.top-of-content {
	align-self: flex-start;
	flex-basis: auto;
	flex-shrink: 0;
	width: 100%;
}

.main {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	width: 100%;
	align-self: flex-start;
}

.header-block {
	flex-basis: auto;
	flex-shrink: 0;
	width: 100%;
	align-self: flex-start;
}

.footer {
	flex-basis: auto;
	flex-shrink: 0;
	width: 100%;
	align-self: flex-end;
}
