// @name Skip menu
// @description SKip menu for accessibility

.menu-skip {
  .desktop {
    display: none;
  }
  .smartphone {
    display: block;
  }
}