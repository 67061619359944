.content {
  h2 {
    font-size: 3em;
    line-height: 1.2;
  }
  p {
    line-height: 1.2;
  }
  .table-wrapper-inner {
    caption {
      text-align: right;
    }
  }
  table {
    td {
      white-space: nowrap;
    }
    tbody {
      th {
        padding-left: 42px;
      }
    }
  }
  blockquote {
    padding-right: 47px;
    padding-top: 0;
    padding-bottom: 0;
  }
  h2.title-content {
    font-size: 25px;
    margin-bottom: 32px;
    &:after {
      width: 28px;
      height: 5px;
    }
  }
  .files-content {
    padding: 30px 20px;
  }
}
.ce-gallery  {
  figure {
    width: 100%;
    img {
      width: 100%;
    }
  }
}