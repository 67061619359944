.go-so-far {
  .go-so-far__title {
    font-size: 25px;
    margin-bottom: 25px;
  }
}
.go-so-far__wrapper {
  padding-left: 0;
}
.go-so-far__item {
  & + .go-so-far__item {
    padding-left: 0;
  }
}