.location,
.content .location {
	padding: 30px 0 30px;
	&-block {
		padding: 0 0 0 3.2%;
		&:after{
			content:"";
			display:block;
			clear:both;
		}
	}
	&-close-btn {
		position: absolute;
		top: 0;
		right: 0;
		left: auto;
		width: 40px;
		height: 40px;
		font-size: 0;
		line-height: 0;
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%) rotate(-45deg);
			-ms-transform: translate(-50%, -50%) rotate(-45deg);
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 2px;
			height: 20px;
			background: #000;		
		}
		&:after {
			-webkit-transform: translate(-50%, -50%) rotate(45deg);
			-ms-transform: translate(-50%, -50%) rotate(45deg);
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
	&-popup {
		text-align: right;
		&-wrap {
			float: left;
			width: 242px;
		}
		p {
			color: #000;
			font-size: 1.3rem;
			line-height: 1.2;
			margin: 0 0 33px;
			&.location-popup-title {
				font-size: 3.5rem;
				line-height: 0.8;
				color: #5090c8;
				margin: 0 0 36px;
				display: block;
				font-weight: 700;
				text-transform: uppercase;
				/*span {
					font-weight: 700;
					font-size: 7.1rem;
					line-height: 0.9;
					display: block;
				}*/
			}
			&.location-popup-info {
				text-transform: uppercase;
				font-size: 1.3rem;
				line-height: 1.2;
				color: #000;
				font-weight: 700;
				margin: 0 0 15px;
				display: block;
			}
		}
	}
	&-maps {
		overflow: hidden;
		position: relative;
		background: url(../Images/bg/bg-map.png);
		background-size: cover;
		background-size: 99.7% 98.8%;
		&-svg {

		}
		&-twocolumns {
			float: left;
			padding: 0 0 0 6.8%;
			width: calc(100% - #{242px});
		}
		&-wrap {
			float: left;
			width: 44.6%;
			margin-top: -95px;
			position: relative;
		}
		&-area {
			width: 99px;
			position:absolute;
			bottom: 10px;
			right: -7px;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		svg {
			polygon {
				cursor: pointer;
				fill: rgba(0,0,0,0.01);
			}
			a {
				&:active,
				&:hover,
				&:focus {
					polygon {
						fill: #572681;
					}
				}
				&.active {
					polygon {
						fill: #572681;
					}
				}
			}

		}
	}
	&-info {
		float: left;
		position: relative;
		width: 43.8%;
		padding: 2px 0 0 0;
		margin: 0 0 0 10.6%;
		&-popup {
			display: none;
			&.active {
				display: block;
			}
		}
		.wrap {
			overflow: hidden;
		}
		&-title {
			color: #000;
			font-size: 2.6rem;
			line-height: 1.2;
			margin: 0 0 19px;
			font-weight: 700;
		}
		&-content {
			overflow: hidden;
			padding: 0 0 2px;
		}
		&-img {
			float: left;
			margin: 0 20px 0 0;
			max-width:120px;
			img {
				margin: 0 0 15px;
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-link {
			display:inline-block;
			vertical-align:top;
			font-size: 1.3rem;
			line-height: 1.2;
			color: #5090c8;
			font-weight: 300;
			text-decoration: underline;
			text-transform: uppercase;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
		&-list {
			font-size: 1.3rem;
			line-height: 1.2;
			color: #000;
			font-weight: 300;
			li {
				margin: 0 0 18px;
			}
			strong {
				font-weight: 700;
				display: block;
				font-style: italic;
				text-transform: uppercase;
			}
		}
		&-subtitle {
			font-weight: 700;
			display: block;
			font-style: italic;
			font-size: 1.3rem;
			line-height: 1.2;
			margin: 0 0 15px;
			text-transform: uppercase;
		}
		p {
			font-size: 1.3rem;
			line-height: 1.2;
			margin: 0 0 16px;
			font-weight: 300;
		}
	}
	&-list {
		font-size: 0;
		line-height: 0;
		li {
			display:inline-block;
			vertical-align:top;
			width: 49%;
			margin: 0 0 6px;
			&:nth-child(2n+1) {
				margin-right: 2%;
			}
		}
		a {
			font-size: 1.5rem;
			line-height: 1.2;
			color: #000;
			text-transform: uppercase;
			position: relative;
			padding-left: 13px;
			&.active {
				text-decoration: underline;
			}
			&:after {
				border-left: 6px solid #5090c8;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				position:absolute;
				top:4px;
				left:0;
				content: '';
			}
		}
	}
	dialog {
		position: static;
		display: block;
		border: none;
		background: none;
	}
	@media(max-width: 1100px) {
		&-maps {
			&-area {
				width: 77px;
			}
		}
	}
}