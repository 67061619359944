// Galerie classique (avec vignettes) + Galerie album
.content {
  .classical-gallery {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 7em;
  }
  .slider-galerie {
    .infos-img {
      padding-left: 10px;
      padding-top: 5px;
    }
  }
  .encadre {
    background: none;
    padding: 1em 39px 1em;
    line-height: 1.3em;
    margin: 0.8em 15px 1.26667em 15px;
  }
  .video__title2 {
    display: none;
  }
  .files-content {
    .file-column {
      width: 100%;
      &:first-child {
        margin-bottom: 23px;
      }
      & + .file-column {
        border-left: none;
        padding-left: 0;
      }
    }
  }
}