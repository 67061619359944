// cookies top block
#cookieManager {
	padding: 0;
	background: #572381;
	border-top: 3px solid #97b911;
	width: 100%;
	margin: 0 auto;
	padding: 50px 25px 30px;
	max-width: 600px;
	.cookieManager-header {
		width: auto;
		padding: 0;
		text-align: center;
		margin: 0;
		position: static;
		background: none;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		.wrapper {
			padding-bottom: 30px;
			border-bottom: 1px dotted #7d7d7d;
		}
		h2 {
			font-weight: $light;
			font-size: 2.4em;
			text-transform: uppercase;
			color: #fff;
			margin-bottom: 10px;
		}
		p {
			font-size: 1.8em;
			color: #fff;
			font-weight: $light;
			&:not(.title) {
				padding: 0 40px;
			}
			&.title {
				font-size: 2.8em;
			}
			&.uppercase {
				text-transform: uppercase;
			}
			+ p {
				margin-top: 40px;
			}
		}
	}
	.wrapNewsletter {
		position: relative;
		margin-top: 30px;
		.wrapField input {
			background: transparent !important;
			border-bottom: 1px solid #fff;
		}
	}
	.wrapField {
		padding-right: 40px;
	}
	.wrapSubmit {
		position: absolute;
		right: 0;
		top: 0;
	}
	form {
		padding: 0;
		margin-top: 30px;
		fieldset {
			margin: 0;
			padding: 0;
			background: none;
			div {
				margin-bottom: 0;
			}
		}
		label {
			font-size: 1.6em;
			text-transform: uppercase;
			color: #fff;
			.description {
				font-size: 0.8125em; // 13px : 1.3/1.6
				text-transform: none;
			}
			span {
				color:#f26382;
			}
		}
		.validate {
			margin: 30px 0 0;
			text-align: center;
			button {
				margin: 0 10px;
			}
		}
	}
}
