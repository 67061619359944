.map-content {
  .mapWrap {
    width: 100%;
    margin-bottom: 25px;
  }
  .contact-info {
    float: none;
    width: 155px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}