// @name Signature stratis
.stratis {
    position: relative;
    text-align: right;
    background: #697cb7;
	z-index: 90;
	font-size: 1.2rem;
	line-height: 1.2;
	color: #fff;
	text-transform: uppercase;
	font-weight: 300;
	font-family: $typo-4;
	span {
		display: none;
	}
	a {
		display: inline-block;
		padding: 4px 10px;
		color: #fff;
		background: #c70000;
		text-decoration: none;
		font-weight: 700;
		margin: 0 0 0 10px;
		&:hover, &:focus {
			color: #c70000;
			background: #fff;
		}
	}
}