.home .header-block {
	min-height: 0;
	&-image {
		display: block;
		height: auto;
	}
}

.header-block {
	padding: 0;
	min-height: 0;
	&-image {
		position: relative;
		top: 0;
		left: 0;
		display: none;
		img {
			position: static;
			-webkit-transform: translate(0, 0);
			-ms-transform: translate(0, 0);
			transform: translate(0, 0);
			min-width: 0;
			min-height: 0;
			width: 100%;
		}
	}
	&-content {
		position: static;
		max-width: none;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		padding: 19px 10px;
	}
	&-description {
		bottom: 0;
		width: 352px;
		height: auto;
		padding: 16px 50px 13px 53px;
	}
	&-title {
		font-size: 2.2rem;
		line-height: 1.25;
	}
	&-search {
		background: #fafbfd;
		margin-bottom: 18px;
		.search-box {
			&__field-wrapper {
				input {
					height: 50px;
					font-size: 13px;
					color: #777;
					padding: 0 17px;
				}
			}
			&__button {
				height: 50px;
				width: 61px;
				svg {
					margin: 5px 0 0 11px;
				}
			}
		}
	}
}