.mosaic {
	display: block;
	padding-top: 6px;
	&-block {
		padding: 22px 0 20px;
	}
	&-col {
		&-lg {
			width: 100%;
		}
		&-sm {
			width: 100%;
		}
	}
	&-item {
		padding-bottom: 15px;
		&-twocolumns {
			display: block;
			.mosaic-item {
				width: 100%;
				height: auto;
			}
		}
	}
	&-box1 {
		padding: 28px 10px 23px;
		&-title {
			font-size: 2.5rem;
			line-height: 1.1;
			margin: 0;
		}
		&-subtitle {
			font-size: 2.4rem;
			line-height: 1.1;
		}
	}
	&-box2 {
		padding: 9px 9px 11px;
		margin: 0 0 -4px;
	}
	&-box3 {
		margin-bottom: -12px;
	}
	&-box4 {
		min-height: 0;
		.mosaic-content {
			padding: 15px 0 7px;
		}
	}
	&-content {
		padding: 11px 0;
	}
	&-title {
		line-height: 1.1;
		&-box {
			&-ico {
				display: none;
			}
			.mosaic-title {
				font-size: 2.4rem;
			}
		}
	}
	&-option-bar {
		max-width: 239px;
		width: 100%;
		padding: 5px 0;
		a {
			padding: 8px 10px;
			display: block;
		}
		&-ico {
			margin: 0 5px 0 0;
			position: static;
			display:inline-block;
			vertical-align:top;
			-webkit-transform: translate(0, 0);
			-ms-transform: translate(0, 0);
			transform: translate(0, 0);
		}
	}
	&-img {
		&-caption {
			padding: 15px 20px;
			font-size: 1.8rem;
		}
	}
}