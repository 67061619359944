.section-1 {
	position: relative;
	z-index: 1;
	background: #fff;
	margin-top: -36px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(-2.5deg);
	-ms-transform: rotate(-2.5deg);
	transform: rotate(-2.5deg);
	&-wrap {
		-webkit-transform: rotate(2.5deg);
		-ms-transform: rotate(2.5deg);
		transform: rotate(2.5deg);
		padding: 36px 0 89px;
	}
}

.section-2 {
	position: relative;
	z-index: 2;
	background: #6072ac;
	margin-top: -36px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(1.8deg);
	-ms-transform: rotate(1.8deg);
	transform: rotate(1.8deg);
	&-wrap {
		-webkit-transform: rotate(-1.8deg);
		-ms-transform: rotate(-1.8deg);
		transform: rotate(-1.8deg);
		padding: 41px 0 107px;
	}
}

.section-3 {
	position: relative;
	z-index: 3;
	background: #f9f9f9;
	margin-top: -75px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(-2.5deg);
	-ms-transform: rotate(-2.5deg);
	transform: rotate(-2.5deg);
	&-wrap {
		-webkit-transform: rotate(2.5deg);
		-ms-transform: rotate(2.5deg);
		transform: rotate(2.5deg);
		padding: 53px 0 110px;
	}
}

.section-4 {
	position: relative;
	z-index: 4;
	background: #ffdc00;
	margin-top: -76px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(1.1deg);
	-ms-transform: rotate(1.1deg);
	transform: rotate(1.1deg);
	overflow: hidden;
	&:after {
		position:absolute;
		top:-59px;
		left:0;
		width: 100%;
		webkit-transform: rotate(-1.1deg);
		-ms-transform: rotate(-1.1deg);
		transform: rotate(-1.1deg);
		background: url(../Images/bg/bg-decor-05.png) no-repeat 50% 0;
		height: 357px;
		z-index: -1;
		content: '';
	}
	&-wrap {
		-webkit-transform: rotate(-1.1deg);
		-ms-transform: rotate(-1.1deg);
		transform: rotate(-1.1deg);
		padding: 41px 0 110px;
	}
}

.section-5 {
	position: relative;
	z-index: 5;
	background: #201d1d;
	margin-top: -75px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(-1.3deg);
	-ms-transform: rotate(-1.3deg);
	transform: rotate(-1.3deg);
	&-wrap {
		-webkit-transform: rotate(1.3deg);
		-ms-transform: rotate(1.3deg);
		transform: rotate(1.3deg);
		padding: 47px 0 110px;
	}
}

.section-6 {
	position: relative;
	z-index: 6;
	background: #f9f9f9;
	margin-top: -57px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(1.1deg);
	-ms-transform: rotate(1.1deg);
	transform: rotate(1.1deg);
	&-wrap {
		-webkit-transform: rotate(-1.1deg);
		-ms-transform: rotate(-1.1deg);
		transform: rotate(-1.1deg);
		padding: 28px 0 70px;
	}
}

.section-7 {
	position: relative;
	z-index: 7;
	background: #fff;
	margin-top: -50px;
	margin-left: -50px;
	margin-right: -50px;
	padding: 0 50px;
	-webkit-transform: rotate(-1.1deg);
	-ms-transform: rotate(-1.1deg);
	transform: rotate(-1.1deg);
	&-wrap {
		-webkit-transform: rotate(1.1deg);
		-ms-transform: rotate(1.1deg);
		transform: rotate(1.1deg);
		padding: 42px 0 55px;
	}
}