.partners {
	padding: 27px 0;
	.heading-block {
		margin: 0;
	}
	&-carousel {
		.item {
			padding: 0;
		}
	}
}