.image-gallery {
	margin: 0 -10px;
	position: relative;
	padding: 0;
	&-block {
		padding: 17px 0 79px;
		position: relative;
	}
	.listItems {
		display: block;
		font-size: 0;
		line-height: 0;
	}
	.item {
		a {
			position: relative;
			img {
				position: static;
				-webkit-transform: translate(0, 0);
				-ms-transform: translate(0, 0);
				transform: translate(0, 0);
				width: 100%;
				min-width: 0;
				min-height: 0;
				display: block;
			}
		}
	}
	.prevCaroussel3,
	.nextCaroussel3 {
		width: 24px;
		height: 100%;
		position:absolute;
		top:0;
		background: rgba(151,185,17,0.7);
		button {
			width: 100%;
			height: 100%;
			position: relative;
			&:after {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				content: '';
			}
			img {
				display: none;
			}
		}
	}
	.prevCaroussel3 {
		left: 0;
		button {
			&:after {
				border-right: 7px solid #201d1d;
			}
		}
	}
	.nextCaroussel3 {
		right: 0;
		button {
			&:after {
				border-left: 7px solid #201d1d;
			}
		}
	}
}