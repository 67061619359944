body.carto, .map-content {
  &.map--loading {
    &::before {
      content: 'Chargement...';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 2.2rem;
      font-weight: $light;
      color: $color-white;
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      z-index: 9999;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
  }
}

.map-content {
  position: relative;
  background: #f9f9f9;
  overflow: hidden;
    margin: 0 0 3rem 0;
  .mapWrap {
    float: left;
    width: 68.5%;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .contact-info {
    float: right;
    width: 31.5%;
    padding: 30px 34px 0;
    /*
    .button {
      display: block;
      width: 100%;
    }
    */
    .title{
      a{
        color:#572381;
        text-decoration: none;
      }
    }
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 7px;
  }
  address {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .list-infos__wrapper {
    display: block;
    margin-bottom: 18px;
  }
  .list-infos__item {
    padding-top: 2px;
    font-weight: 300;
    a {
      text-decoration: none;
    }
  }
}