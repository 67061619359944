.mobile-menu {
	&-opener {
		margin: 11px 0 0 10px;
		width: 36px;
		height: 36px;
		span {
			width: 16px;
			height: 4px;
			&:after,
			&:before {
				width: 16px;
				height: 4px;
				top: -6px;
			}
			&:before {
				top: 6px;
			}
		}
	}
}