.news-list,
.content .news-list { 
	margin: 0;
	li {
		width: 100%;
		padding: 0 0 25px;
	}
	&-img {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}