// @name Heading single news
// @dependency .heading
.heading {
  .heading-text {
    float: left;
    width: 55%;
  }
  .teaser-2 {
    font-size: 1.8em;
    line-height: 1.5;
    color: #79766f;
    margin-bottom: 22px;
  }
  .heading__picture {
    img {
      width: auto;
      max-width: 100%;
    }
  }
}