.social-list {
	margin: 0 0 12px;
	padding-top: 5px;
	li {
		width: 100%;
		padding: 0 0 19px;
		&.tablet-two-line{
			width: 100%;
		}
	}
	&-post {
		&-content {
			padding: 17px 17px 19px;
		}
	}
}