// @name Label

// @name Input radio and checkbox
// @description
// @state .column-2 - Indicates the column positioning
.radio, .checkbox {
  margin: 2em 0 1.5em;
  &.column-2 {
    div {
      display: block;
      vertical-align: inherit;
      width: 100%;
    }
  }
  legend {
    margin-top: 0;
  }
}